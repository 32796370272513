/**
 * Pills - styles
 */

import styled from 'styled-components';

export const PillsStyles = styled.div``;

export const SelectedAreaFilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: ${props => props.theme.spacing.space1}rem;
`;

export const SelectedAreaFilter = styled.div`
	display: flex;
	align-items: center;
	${props => props.theme.typography.style9.getStyle()};		
	color: ${props => props.theme.palette.text.secondary};
	margin-right: ${props => props.theme.spacing.space1}rem;
	margin-bottom: ${props => props.theme.spacing.space1}rem;
	padding: 4px;
	padding-left: ${props => props.theme.spacing.space1}rem;
	border-radius: 2px;

	background-color: ${props => props.theme.colors.black};
	border-radius: 7px;

	span {
		padding-top: 3px;
	}

	button {
		height: 28px;
		width: 28px;
		background-color: ${props => props.theme.colors.black};
		border-radius: 2px;
		margin-left: ${props => props.theme.spacing.space1}rem;
		margin-right: ${props => props.theme.spacing.space1 / 2}rem;
	}
	button > svg {
		justify-self: center;
		width: 100%;
		height: 100%;
		padding: 4px;
	}
	button::after {
		content: none;
	}
`;

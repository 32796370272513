/**
 * Typography - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { mediaQueries } from 'theme';
import { resetAccessibilityFocus, styleForLink } from 'theme/styles';

export const zeroMarginPadding = css`
	margin: 0px;
	padding: 0px;
`;

/**
 *  Span
 */
export const Span = styled.span`
	${(props) => props.theme.typography.style8.getStyle()};
`;

/**
 *  Div
 */
export const Div = styled.div`
	${(props) => props.theme.typography.style8.getStyle()};
`;

/**
 *  H1
 */
export const styleForH1 = css`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style1.getStyle()};
	color: ${(props) => props.theme.colors.primary};
	margin-bottom: ${(props) => props.theme.spacing.space2}rem;
`;

export const H1 = styled.h1`
	${styleForH1};
`;

/**
 *  H2
 */
export const styleForH2 = css`
	${zeroMarginPadding};
	color: ${(props) => props.theme.colors.primary};
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
	${(props) => props.theme.typography.style2.getStyle()};
`;

export const H2 = styled.h2`
	${styleForH2};
`;

/**
 *  H3
 */
export const styleForH3 = css`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style3.getStyle()};
	color: ${(props) => props.theme.colors.primary};
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const H3 = styled.h3`
	${styleForH3};
`;

/**
 *  H4
 */
export const styleForH4 = css`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style4.getStyle()};
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const H4 = styled.h4`
	${styleForH4};
`;

/**
 *  H5
 */
export const styleForH5 = css`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style5.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
	margin-bottom: ${(props) => props.theme.spacing.space1}rem;
`;

export const H5 = styled.h5`
	${styleForH5};
`;

/**
 *  H6
 */
export const styleForH6 = css`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style6.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const H6 = styled.h6`
	${styleForH6};
`;

/**
 *  P
 */
export const styleForP = css`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style8.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
	max-width: 65ch;
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const P = styled.p`
	${styleForP};
`;

/**
 *  Preamble
 */
export const styleForPreamble = css`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style7.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
	max-width: 65ch;
	margin-bottom: ${(props) => props.theme.spacing.space3}rem;
`;

export const Preamble = styled.p`
	${styleForPreamble};
`;

/**
 *  Button
 */
export const styleForButton = css`
	${(props) => props.theme.typography.style11.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
`;

export const Button = styled.p`
	${styleForButton};
`;

/**
 * Input
 */
export const styleForInput = css`
	${(props) => props.theme.typography.style8.getStyle()};
	border-radius: 8px;
	border: 1px solid rgba(0, 0, 0, 0.32);
	background-color: ${(props) => props.theme.colors.white};
	padding-left: ${(props) => props.theme.spacing.space2}rem;
	padding-right: ${(props) => props.theme.spacing.space2}rem;
	padding-top: ${(props) => (props.theme.isMicrositeActive ? '10px' : '7px')};
	padding-bottom: 9px;
	margin-right: ${(props) => props.theme.spacing.space2}rem;

	&:focus {
		${resetAccessibilityFocus()}
		box-shadow: 0 0 0 2px ${(props) => props.theme.colors.secondaryBlue};
		transition: box-shadow 200ms ease-in-out;
	}
`;

/**
 * Label
 */
export const styleForLabel = css`
	${(props) => props.theme.typography.style9.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
`;

/**
 * Select
 */
export const styleForSelect = css`
	${(props) => props.theme.typography.style9.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
	font-family: 'Arial'; // Hack for fixing cropping 'ÅÄÖ...'
	border-radius: 8px;
	padding: 10px;
	padding-bottom: 9px;
	border: 1px solid rgba(0, 0, 0, 0.32);
	background-color: ${(props) => props.theme.colors.white};
`;

export const Select = styled.select`
	${styleForSelect};
`;

export const ScreenReaderText = styled.span`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const styleForTable = css`
	border: 1px solid ${(p) => p.theme.colors.black}15;
	border-spacing: 0;
	border-radius: 0.25rem;
	font-size: 1rem;
	line-height: 1.5;
	width: 100%;
	margin: 0 auto;

	caption {
		${styleForH2}
		text-align: left;
		${mediaQueries.phone} {
			width: 90vw;
			text-align: left;
			${styleForH2}
		}
	}

	& tbody tr:last-child {
		th,
		td {
			border-bottom: 0;
		}
	}

	& thead th {
		border-bottom: 1px solid ${(p) => p.theme.colors.black}15;
	}

	th,
	td {
		padding: 1rem 1.5rem;
		border: 0;
		font-family: Tiempos Text;
		font-size: 1.125rem;
		border-bottom: 1px solid ${(p) => p.theme.colors.black}15;
		border-right: 1px solid ${(p) => p.theme.colors.black}15;
		& p {
			padding: 0;
			margin: 0;
		}
	}
	th {
		text-align: left;
	}
`;

export const Table = styled.table`
	${styleForTable}
`;

export const styleForScrollableTableWrapper = css`
	${mediaQueries.phone} {
		width: 100%;
		overflow-y: auto;
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
		position: relative;

		> table {
			margin-top: 0;
			margin-bottom: 0;
			width: auto;
			max-width: none;
		}

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: ${px2rem(14)};
			height: ${px2rem(14)};
		}

		&::-webkit-scrollbar-thumb {
			border-radius: ${px2rem(8)};
			border: ${px2rem(3)} solid #fff;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
`;

export const ScrollableTableWrapper = styled.div`
	${styleForScrollableTableWrapper}

	table {
		${styleForTable}
	}
`;

// prettier-ignore
export const styleForRichText = css`
h1 {
	${styleForH1}
}
h2 {
	${styleForH2}
}
h3 {
	${styleForH3}
}
h4 {
	${styleForH4}
}
h5 {
	${styleForH5}
}
h6 {
	${styleForH6}
}
button {
	${styleForButton}
}
p {
	${styleForP}
}

ul {
	list-style-position: outside;
	margin: 0;
	padding: 0;
	padding-left: ${props => props.theme.spacing.space2 + 0.25}rem;
}

li > ul {
	margin-top: ${props => props.theme.spacing.space3}rem;
}

li {
	${styleForP}
}

a {
	${styleForLink}
}


table {
	${styleForTable}
	margin: 2.5rem 0;
}
	.table-scrollable {
	${styleForScrollableTableWrapper}
}
	.icon {
	display: inline-block;
	width: ${px2rem(12)};
	height: ${px2rem(12)};
	margin-left: 0.5rem;
	margin-right: 0.3rem;
	background-repeat: no-repeat;
}
`;

export const RichText = styled.div`
	${styleForRichText};

	> div > *:last-child {
		margin-bottom: 0rem;
	}

	> div > p:last-child,
	> div > ul > li,
	> div > ol > li {
		margin-bottom: ${(props) => props.theme.spacing.space2}rem;
	}
`;

import Button from 'components/Button';
import { ButtonVariant } from 'pages/sharedModelTypes';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
	SelectedAreaFilter,
	SelectedAreaFilterContainer,
} from './Pills.styles';

/**
 * Pills
 */
export type PillItem = {
	id: string | number;
	label: string;
	data?: any;
};

export type PillsProps = {
	items: PillItem[];
	onRemove?: (item: PillItem) => void;
};

export const Pills: React.FC<PillsProps> = ({ items, onRemove }) => {
	// TODO: Better aria label text with label value
	const ariaLabel = 'Ta bort sökord'; // TODO: Hardcoded text
	const themeContext = useContext(ThemeContext);

	const onItemClicked = (item: PillItem) => {
		if (onRemove) {
			onRemove(item);
		}
	};

	return (
		<>
			{items.length > 0 && (
				<SelectedAreaFilterContainer>
					{items.map((option, index) => {
						return (
							<SelectedAreaFilter key={index}>
								<span>{option.label}</span>
								<Button
									aria-label={ariaLabel}
									iconName="cross"
									iconColor={themeContext.colors.white}
									onClick={() => onItemClicked(option)}
									variant={ButtonVariant.Link}
								></Button>
							</SelectedAreaFilter>
						);
					})}
				</SelectedAreaFilterContainer>
			)}
		</>
	);
};

export default Pills;

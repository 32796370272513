import React, { useContext, useEffect, useState } from "react";
import { MicrositeQuizPageModel } from "./MicrositeQuizPage.model";
import QuizForm from "../../../components/microsite/QuizForm";
import PageTopBanner from "components/PageTopBanner";
import Space from "components/Boilerplate/Space";
import { OnPageNavigationContent, getBlockBaseMenuItems, getBlockBaseSections } from "components/Panels/OnPageNavigationContent";
import EpiFragments from "components/Boilerplate/EpiFragments";
import { ThemeContext } from 'styled-components';
import { BlockType, getLastItemBlockType } from "components/Boilerplate/EpiFragments/EpiFragments";
import { FragmentModelTypes } from "types/fragments";
import MegaMenu from "components/MegaMenu";
import { NavigationItem } from "types/epi";


const MicrositeQuizPage: React.FC<MicrositeQuizPageModel> = ({
    questions,
    themeTop,
    mainImage,
    decal,
    heading,
    subHeading,
    _properties,
    prevButtonText,
    nextButtonText,
    preamble,
    lastReviewed,
    resultButtonText,
    resultTitle,
    yourAnswerText,
    epi,
    correctAnswerText,
    minimumPassing,
    passedText,
    failedText,
    bottomItems,
    textAsModel,
    disableCustomHeadingLogic

}) => {
	const themeContext = useContext(ThemeContext);
	const [menuIndex, setMenuIndex] = useState<NavigationItem[]>([]);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [sections, setSections] = useState<string[]>([]);
    const ref = React.createRef<any>();


    useEffect(() => {
		const expanderModels = getBlockBaseSections(bottomItems);
		const sectionStrings = expanderModels.map((item) => item.onPageMenuHeading);

		if (sectionStrings.length > 0) {
			const menuIndexRoot = getBlockBaseMenuItems(sectionStrings);
			setMenuIndex(menuIndexRoot);
            setSections([]);
		} else {
			setMenuIndex([]);
            setSections([]);
		}
	}, [bottomItems]);

    let lastBlockType = BlockType.Element;
    let blockTypeBefore_bottomItems: BlockType = BlockType.Element;

	if (textAsModel && textAsModel.fragments.length > 0) {
		lastBlockType = getLastItemBlockType(
			textAsModel.fragments,
			themeContext,
			disableCustomHeadingLogic
		);
		blockTypeBefore_bottomItems = lastBlockType;
	}

    const onCloseMenuEvent = (userClosed: boolean) => {
		setIsMenuOpen(false);
		if (userClosed && ref.current) {
			ref.current.focus();
		}
	};

    var DisplayNavigationHeader = false;

	if (
		(bottomItems && bottomItems.length > 0) ||
		(textAsModel && textAsModel?.fragments.length > 0)
	) {
		//we should display the navigationHeader if either TextAsModel or bottomItems has a value
		DisplayNavigationHeader = true;
	}

    return (
        <>
            <PageTopBanner
				mainImage={mainImage}
				themeTop={themeTop}
				decal={decal}
				headingLevel={1}
				heading={heading}
				subheading={subHeading}
				heading_htmlAttributes={_properties?.heading}
				mainImage_htmlAttributes={_properties?.mainImage}
			></PageTopBanner>

            {DisplayNavigationHeader && (
				<Space
					top={themeContext.spacing.getElement()}
					bottom={themeContext.spacing.getElement()}
				>
					<OnPageNavigationContent
						headingLevel={2}
						heading={null}
						heading_htmlAttributes={_properties?.heading}
						lastReviewed={lastReviewed}
						preamble={preamble}
						preamble_htmlAttributes={_properties?.preamble}
						textAsModel={textAsModel}
						textAsModel_htmlAttributes={_properties?.mainImage}
						sections={sections}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					></OnPageNavigationContent>
				</Space>
			)}

    
            <Space top={themeContext.spacing.getElement()} bottom={themeContext.spacing.getElement()}>
                <QuizForm
                questions={questions}
                prevButtonText={prevButtonText}
                nextButtonText={nextButtonText}
                resultButtonText= {resultButtonText}
                resultTitle={resultTitle}
                yourAnswerText={yourAnswerText}
                correctAnswerText={correctAnswerText}
                minimumPassing={minimumPassing}
                passedText={passedText}
                failedText={failedText} 
                />
            </Space>

            <Space top={themeContext.spacing.getElement()} bottom={themeContext.spacing.getElement()}>
                <EpiFragments
                    previousBlockType={blockTypeBefore_bottomItems}
                    epi={epi}
                    headingLevel={2}
                    fragments={bottomItems as FragmentModelTypes[]}
                    disableCustomHeadingLogic={disableCustomHeadingLogic}
                />
            </Space>

            {isMenuOpen && (
				<MegaMenu
					items={menuIndex}
					heading={heading}
					onClose={onCloseMenuEvent}
				/>
			)}
        </>
    )
}

export default MicrositeQuizPage
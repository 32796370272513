/**
 * LinkTableList
 * todo: handle no theme, heading should be darkblue
 */

import { Cell, Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import {
	AreaItem,
	getThemeHeadingColor,
	PuffTheme,
} from 'pages/sharedModelTypes';
import React, { useContext, useEffect, useState } from 'react';
import {
	AreaContainer,
	GridWrapper,
	LinkTableListStyle,
} from './LinkTableList.styles';
import { ShowMoreButton } from './ShowMoreButton';
import ListHeader from 'components/ListHeader';
import { ThemeContext } from 'styled-components';

type LinkTableListProps = {
	theme?: PuffTheme;
	headingLevel?: number;
	heading: string;
	items: AreaItem[];
	showMoreLabel: string;
	initialMaxCount?: number;
};

export const LinkTableList: React.FC<LinkTableListProps> = ({
	theme,
	headingLevel,
	heading,
	showMoreLabel,
	initialMaxCount,
	items,
}) => {

	const themeContext = useContext(ThemeContext);

	const [showItemsCount, setShowItemsCount] = useState<number>(0);
	const [showMoreButton, setShowMoreButton] = useState<boolean>(false);
	const [filterdItems, setFilterdItems] = useState<AreaItem[]>([]);

	useEffect(() => {
		let itemsToShow = initialMaxCount ? initialMaxCount : items.length;
		if (itemsToShow > items.length) {
			itemsToShow = items.length;
		}

		setShowItemsCount(itemsToShow);
	}, [initialMaxCount, items]);

	useEffect(() => {
		setShowMoreButton(showItemsCount < items.length);
		setFilterdItems(items.slice(0, showItemsCount));
	}, [items, showItemsCount]);

	return (
		<LinkTableListStyle themeName={theme}>
			<Grid paddingTop={false} paddingBottom={false}>

				<ListHeader
					heading={heading}
					sectionStyle
					headingLevel={headingLevel}
					color={getThemeHeadingColor(themeContext, theme)}
				>
				</ListHeader>

				<Space top={themeContext.spacing.space3} bottom={themeContext.spacing.space5}>
					<GridWrapper inner={true}>
						{filterdItems.map((item, index) => {
							return (
								<Cell key={index} span={6} tablet={8} phone={4}>
									<AreaContainer to={item.link.url}>
										{item.link.heading}
										{item.numberOfPages !== null &&
											`\u00A0(${item.numberOfPages})`}
									</AreaContainer>
								</Cell>
							);
						})}
					</GridWrapper>
				</Space>

				{showMoreButton && (
					<ShowMoreButton
						header={showMoreLabel}
						onClick={() => setShowItemsCount(items.length)}
					></ShowMoreButton>
				)}
			</Grid>
		</LinkTableListStyle>
	);
};

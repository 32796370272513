import React, { useEffect, useRef } from "react";
import { QuizInput, QuizLabel, QuizList, QuizOption, QuizText } from "./Quiz.styles";
import EpiFragments from "components/Boilerplate/EpiFragments";
import { FragmentListModel } from "types/fragments";
import { AnswerModel } from "hooks/useQuizForm";
import Typography, { Heading } from "components/Typography/Typography";


export interface QuizModel {
	heading: string;
	correctAnswer: string;
	answerOptions: string[];
	mainBody: FragmentListModel | null;
    correctAnswerExplination: FragmentListModel | null;
    selectedQuiz: number;
    setAnswer: (newAnswer: AnswerModel) => void;
    selectedAnswer: AnswerModel;
}


const Quiz:React.FC<QuizModel> = ({
    heading,
	answerOptions,
	mainBody,
    correctAnswer,
    correctAnswerExplination,
    selectedQuiz,
    selectedAnswer,
    setAnswer,
}) => {
    const headingRef = useRef<HTMLHeadingElement>(null);

    const handleSelectAnswer = (answer: string) => {
        setAnswer({
            questionIndex: selectedQuiz, 
            answer: answer, 
            question: heading, 
            correctAnswer,
            questionDescription: mainBody,
            answerOptions: answerOptions,
            correctAnswerExplination: correctAnswerExplination
        });
    }

    const handleEnterPress = (e: React.KeyboardEvent<HTMLLabelElement>, answer: string) => {
        e.preventDefault();
        if (e.key === "Enter") {
            handleSelectAnswer(answer);
        }
    }

    useEffect(() => {
        if(headingRef.current) headingRef.current?.focus();
    },[heading])

    return (
        <>
            <Typography as="h2" ref={headingRef} tabIndex={-1}>
               {heading}
            </Typography>
            <div style={{ marginLeft: "-16px" }}>
                <EpiFragments  fragments={mainBody?.fragments} />
            </div>
            <QuizList>
                {answerOptions.map((answer) => (
                    <QuizOption>
                            <QuizLabel  
                            tabIndex={0}
                            aria-label={answer}
                            onKeyPress={event => handleEnterPress(event, answer)}
                            >
                                <QuizInput
                                tabIndex={-1}
                                onChange={() => handleSelectAnswer(answer)} 
                                checked={selectedAnswer?.answer == answer} 
                                name="answer"
                                value={answer} 
                                type="radio"/>
                                <QuizText>{answer}</QuizText>
                            </QuizLabel>
                    </QuizOption>
                ))}
            </QuizList>
        </>
    )
}

export default Quiz
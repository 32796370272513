/**
 * CheckBox
 */

import React, { useEffect, useState } from 'react';
import {
	CheckboxContainer,
	HiddenCheckbox,
	Icon,
	StyledCheckbox,
} from './Checkbox.styles';

export type CheckboxProps = {
	checked?: boolean;
	disabled?: boolean;	// TODO: Not implemented
	children?: any;
	required?: boolean;
	name?: string;
	value?: any;	// Used for FormData
	id?: string;
	onCheckedChanged?: (checked: boolean) => void;
};

/** A Checkbox */
const Checkbox = React.forwardRef<any, CheckboxProps>(
	({
		checked = false,
		children,
		onCheckedChanged,
		required,
		value,
		...props
	}, ref) => {
		const [checkedState, setCheckedState] = useState(checked);

		const handleCheckboxChange = (event: any) => {
			setCheckedState(event.target.checked);

			if (onCheckedChanged) {
				onCheckedChanged(event.target.checked);
			}
		};

		useEffect(() => {
			setCheckedState(checked);
		}, [checked]);

		return (
			<CheckboxContainer>
				<label>
					<HiddenCheckbox
						ref={ref}
						checked={checkedState}
						value={value}
						{...props}
						onChange={handleCheckboxChange}
					/>
					<StyledCheckbox checked={checkedState}>
						<Icon viewBox="0 0 24 24">
							<polyline points="19 7 11 16 5 11" />
						</Icon>
					</StyledCheckbox>
					<span>{children}</span>
					{required && <abbr title="Obligatoriskt">*</abbr>}

				</label>
			</CheckboxContainer>
		);
	});

export default Checkbox;

/**
 * MenuMobile
 * https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
 */
import Icon from './../Boilerplate/Icon';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import {
	MenuContainer,
	MenuListItemContainer,
	MenuItemHeader,
	MenuHeader,
	MenuLinkItemStyle1,
	MenuLinkItem,
	CloseButton,
	MicrositeCloseButton,
	MicrositeMenuItemHeader,
	MicrositeMenuHeader,
} from './MenuMobile.styles';
import Link from 'components/Boilerplate/Link';
import { LogoModel, NavigationItem } from 'types/epi';
import { selectLogo, toggleMenuItem } from 'store/modules/model';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import FocusTrap from 'focus-trap-react';

export interface MenuMobileProps {
	items: NavigationItem[];
	onClose?: (focusOrigin: boolean) => void;
	micrositeLogo?: LogoModel;
}

const MenuMobile: React.FC<MenuMobileProps> = ({
	items,
	onClose,
	micrositeLogo,
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [currentLocation, setCurrentLocation] = useState(location.pathname);
	const ref = useRef(null);
	// bring logo props
	const logo = useSelector(selectLogo);

	const escapeListener = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Escape' && onClose) {
				onClose(true);
			}
		},
		[onClose]
	);

	useEffect(() => {
		document.addEventListener('keyup', escapeListener);
		return () => document.removeEventListener('keyup', escapeListener);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const firstMenuItem: any = document?.querySelectorAll('#menu-list a')[0];
		firstMenuItem?.focus();
	}, []);

	useEffect(() => {
		if (onClose && currentLocation !== location.pathname) {
			onClose(false);
		}
		setCurrentLocation(location.pathname);
	}, [onClose, currentLocation, location]);

	const toggleOpenState = (item: NavigationItem) => {
		// UI => Action => Reducer => Store => UI > ...

		// Toggle the clicked/activated item
		const result = createNodeIndexPath(items, item, []);
		if (result) {
			dispatch(toggleMenuItem(result));
		}
	};

	const createNodeIndexPath = (
		items: NavigationItem[],
		find: NavigationItem,
		path: number[]
	): number[] | null => {
		for (let i = 0; i < items.length; i++) {
			let item = items[i];
			if (item === find) {
				return [...path, i];
			}

			if (item.children.length > 0) {
				let result = createNodeIndexPath(item.children, find, [...path, i]);
				if (result) {
					return result;
				}
			}
		}
		return null;
	};

	const onHeaderClick = () => {
		if (onClose) {
			onClose(true);
		}
	};

	return (
		<FocusTrap active={true} focusTrapOptions={{ preventScroll: true }}>
			<MenuContainer ref={ref}>
				{micrositeLogo ? (
					<MicrositeMenuHeader height={micrositeLogo?.height}>
						<img
							data-testid="logo microsite"
							src={micrositeLogo?.src}
							alt={micrositeLogo?.alt}
						/>
						<MicrositeCloseButton onClick={onHeaderClick}>
							<span>Stäng</span>
							<Icon size={2} icon="cross"></Icon>
						</MicrositeCloseButton>
					</MicrositeMenuHeader>
				) : (
					<MenuHeader>
						<img data-testid="logoImage" src={logo?.src} alt={logo?.alt} />
						<CloseButton onClick={onHeaderClick}>
							<span>Stäng</span>
							<Icon size={2} icon="cross"></Icon>
						</CloseButton>
					</MenuHeader>
				)}

				<MenuList items={items} onToggleOpenState={toggleOpenState} />
			</MenuContainer>
		</FocusTrap>
	);
};

export default MenuMobile;

/**
 * MenuList
 */

export interface MenuListProps {
	items: NavigationItem[];
	level?: number;
	onToggleOpenState?: (item: NavigationItem) => void;
}

const MenuList: React.FC<MenuListProps> = ({
	items,
	level = 0,
	onToggleOpenState,
}) => {
	const toggleOpenState = (item: NavigationItem) => {
		if (onToggleOpenState) {
			onToggleOpenState(item);
		}
	};

	return (
		<nav>
			<ul id="menu-list" tabIndex={-1} aria-labelledby="menu label">
				{items.map((item, index) => {
					return (
						<MenuListItemContainer
							key={index}
							level={level}
							isOpen={item.isActive}
						>
							<MenuItemComponent
								hasChildren={item.children.length > 0}
								level={level}
								isOpen={item.isActive}
								url={item.url}
								style={item.style}
								heading={item.text}
								onChangeState={() => toggleOpenState(item)}
								isRedirect={item.isRedirect}
							/>

							{item.isActive && (
								<MenuList
									onToggleOpenState={onToggleOpenState}
									items={item.children}
									level={level + 1}
								/>
							)}
						</MenuListItemContainer>
					);
				})}
			</ul>
		</nav>
	);
};

export type ItemProps = {
	level: number;
	isOpen?: boolean;
};

export type MenuItemComponentProps = {
	url: string | null;
	heading: string;
	level: number;
	isOpen: boolean;
	hasChildren: boolean;
	style?: number;
	onClick?: () => void;
	onChangeState?: () => void;
	isRedirect: boolean;
};

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({
	url,
	heading,
	level,
	isOpen,
	hasChildren,
	style = 0,
	onClick,
	onChangeState,
	isRedirect,
}) => {
	const themeContext = useContext(ThemeContext);

	if (!hasChildren && url) {
		if (level === 0 || style === 1) {
			return (
				<MenuLinkItemStyle1 level={level}>
					<Link showLinkIcon={false} to={url} aria-label={heading}>
						{heading}
					</Link>
				</MenuLinkItemStyle1>
			);
		}

		return (
			<MenuLinkItem isRedirect={isRedirect}>
				<Link showLinkIcon={false} to={url} aria-label={heading}>
					{heading}
				</Link>
			</MenuLinkItem>
		);
	}

	return (
		<>
			{themeContext.isMicrositeActive ? (
				<MicrositeMenuItemHeader
					aria-expanded={isOpen}
					aria-label={heading}
					level={level}
					isOpen={isOpen}
					onClick={onChangeState}
				>
					<div>{heading && <span>{heading}</span>}</div>
					{hasChildren && (
						<div>
							<Icon
								icon="chevron"
								color={
									isOpen
										? themeContext.palette.text.secondary
										: themeContext.palette.text.primary
								}
							/>
						</div>
					)}
				</MicrositeMenuItemHeader>
			) : (
				<MenuItemHeader
					aria-expanded={isOpen}
					aria-label={heading}
					level={level}
					isOpen={isOpen}
					onClick={onChangeState}
				>
					<div>{heading && <span>{heading}</span>}</div>
					{hasChildren && (
						<div>
							<Icon
								icon="chevron"
								color={
									isOpen
										? themeContext.palette.text.secondary
										: themeContext.palette.text.primary
								}
							/>
						</div>
					)}
				</MenuItemHeader>
			)}
		</>
	);
};

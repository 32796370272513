import React, { useContext, useEffect, useState } from 'react';
import Space from 'components/Boilerplate/Space';
import { NVsePublicationsListingPageModel } from './NVsePublicationsListingPage.model';
import { Grid } from 'components/Boilerplate/Grid';
import Tab, { TabPanel, TabProps } from 'components/Tab/Tab';
import {
	InlineControlls,
	CheckboxesInline,
	FieldsetInline,
} from './NVsePublicationsListingPage.styles';
import { LinkType, NumberRage, PuffTheme } from 'pages/sharedModelTypes';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchSearchResult,
	selectLocalization,
	setSessionStorage,
} from 'store/modules/model';
import { selectReact } from 'store/modules/react';
import { ApiPath } from 'api/epiApi';
import Pagination from 'components/Pagination';
import { ViewType } from '../../types/enums';
import {
	FragmentModelTypes,
	ImageFragmentModel,
	PublicationPuffModel,
} from 'types/fragments';
import Checkbox from 'components/Checkbox';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { buildQueryString, formatText, translate } from 'utils/helper-utils';
import { FieldsetContainer } from 'components/Panels/Panels.styles';
import { CategoryDropdown } from 'pages/NVseRegulationsListingPage/CategoryDropdown';
import { DateRangeDropdown } from 'pages/NVseRegulationsListingPage/DateRangeDropdown';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { LinkTableList } from 'components/Panels/LinkTableList';
import { SearchInputForm } from 'components/Panels/SearchInputForm';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import {
	createPageSessionStorage,
	usePageSessionStorage,
} from 'hooks/useSessionStorage';
import { SearchResultModel } from 'types/epi';
import ListHeader from 'components/ListHeader';
import { ThemeContext } from 'styled-components';

type PublicationSearchResultItemModel = {
	heading: string | null;
	decal: string | null;
	link: LinkType | null;
	releaseDate: string | null;
	image: ImageFragmentModel | null;
};

type PublicationSearchParams = {
	query?: string;
	page?: number;
	size?: number;
	catId?: string;
	lang?: string;
	startyear?: number;
	endyear?: number;
};

export interface CategoryList {
	[key: string]: string;
}

type PublicationsListingPageSessionModel = {
	selectedPanelId: string;
	selectedViewType: ViewType;
	languages: string[];
	numberRange: NumberRage;
	categories: CategoryList;
	page: number | null;
	query: string;
	searchResponse: SearchResultModel | null;
};

/**
 * # Listningssida för publikationer
 * Modeltype:<code>NVsePublicationsListingPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2197258277/NVsePublicationsListingPage)
 *
 * Landningssida för webbplatsens publikationer
 */ const NVsePublicationsListingPage: React.FC<NVsePublicationsListingPageModel> = ({
	id,
	modelType,
	heading,
	preamble,
	exploreLabel,
	searchLabel,
	topItems,
	publicationsAreaLabel,
	searchInputLabel,
	displayedPublicationsText,
	areas,
	categories,
	languages,
	selectableYears,
	bottomItems,
	epi,
	apiUrl,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const themeContext = useContext(ThemeContext);
	const { apiUrl: baseApiUrl } = useSelector(selectReact);
	const localization = useSelector(selectLocalization);

	const includeLabel = translate(
		'/frontend/pages/includeonly',
		'Include only',
		localization
	);

	const initialSessionDate: PublicationsListingPageSessionModel = {
		selectedPanelId: 'explore',
		selectedViewType: ViewType.List,
		languages: [],
		numberRange: {
			from: selectableYears.startYear,
			to: selectableYears.endYear,
		},
		categories: {},
		page: null,
		query: '',
		searchResponse: null,
	};

	const dispatch = useDispatch();

	const [fixedResults, setFixedResults] = useState<FragmentModelTypes[]>([]);
	const pageSessionStorage = usePageSessionStorage<PublicationsListingPageSessionModel>(
		modelType,
		id,
		initialSessionDate
	);

	// TODO: Hardcoded texts
	const showMoreLabel = 'Visa alla';
	const tabPanelProps = {
		label: 'Sidor',
		defaultTabId: pageSessionStorage.selectedPanelId,
		autoSelect: false,
		items: [
			{
				heading: exploreLabel,
				id: 'explore',
				iconName: null,
			},
			{
				heading: searchLabel,
				id: 'search',
				iconName: 'search',
			},
		],
	} as TabProps;

	useEffect(() => {
		const getFixedSearchResult = (
			searchResponse: SearchResultModel
		): PublicationPuffModel[] => {
			if (searchResponse) {
				return searchResponse.results.map(
					(item: PublicationSearchResultItemModel) => {
						return {
							modelType: 'PublicationPuffModel',
							decal: item.decal,
							heading: item.heading,
							publicationDate: item.releaseDate,
							image: item.image,
							link: item.link,
						} as PublicationPuffModel;
					}
				);
			}
			return [];
		};

		if (pageSessionStorage.searchResponse) {
			setFixedResults(getFixedSearchResult(pageSessionStorage.searchResponse));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSessionStorage]);

	useEffect(() => {
		// Make a search first time we enter the TAB.
		if (
			pageSessionStorage.selectedPanelId === 'search' &&
			pageSessionStorage.searchResponse === null
		) {
			executeQuery(pageSessionStorage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSessionStorage.selectedPanelId]);

	const handleSubmit = (query: string) => {
		const data = {
			...pageSessionStorage,
			page: 1,
			query,
		} as PublicationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const executeQuery = (state: PublicationsListingPageSessionModel) => {
		const urlQuery = buildQueryFromModel(state);
		// TODO: Switch to real API
		// dispatch(fetchSearchResult(modelType, id, "searchResponse", "https://8167b974-87ff-4a2b-93a0-e92912c37eef.mock.pstmn.io", ApiPath.NVseSearchPageDemo, urlQuery));

		dispatch(
			fetchSearchResult(
				modelType,
				id,
				'searchResponse',
				baseApiUrl,
				apiUrl,
				urlQuery
			)
		);
		// move to the top after got results
		window.scrollTo(0, 0);
	};

	const buildQueryFromModel = (
		pageState: PublicationsListingPageSessionModel
	): string => {
		const queryParams = {
			page: pageState.page,
			startyear: pageState.numberRange.from,
			endyear: pageState.numberRange.to,
		} as PublicationSearchParams;

		if (pageState.query) {
			queryParams.query = pageState.query;
		}

		// Special handling for language, the API can only handle one language at a time
		if (pageState.languages.length === 1) {
			// All languages selected => set language to blank
			//fixdFormModel.languages = null;
			queryParams.lang = pageState.languages[0];
		}

		// Special handling for categories, the API can only handle one category at a time
		if (Object.keys(pageState.categories).length) {
			// Pick first category
			queryParams.catId =
				pageState.categories[Object.keys(pageState.categories)[0]];
		}

		return buildQueryString(queryParams);
	};

	//#region Tab-page handling

	const onSelectedTab = (panelId: string) => {
		const data = {
			...pageSessionStorage,
			selectedPanelId: panelId,
		} as PublicationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));
	};

	const onListViewChanged = (view: ViewType) => {
		const data = {
			...pageSessionStorage,
			selectedViewType: view,
		} as PublicationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));
	};

	//#endregion

	const onCategorySelected = (index: Number, catId: string) => {
		const newCategories = { ...pageSessionStorage.categories };
		if (catId) {
			newCategories[index.toString()] = catId;
		} else if (newCategories[index.toString()]) {
			delete newCategories[index.toString()];
		}

		const data = {
			...pageSessionStorage,
			page: 1,
			categories: newCategories,
		} as PublicationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const onYearRangeChanged = (range: NumberRage) => {
		const data = {
			...pageSessionStorage,
			page: 1,
			numberRange: range,
		} as PublicationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const onLanguageCheckedChanged = (code: string, value: boolean) => {
		if (value && pageSessionStorage.languages.includes(code)) {
			return;
		}
		const newPageState = { ...pageSessionStorage, page: 1 };
		const languages = [...newPageState.languages];
		// Remove
		if (value === false) {
			var index = languages.findIndex((item) => {
				return item === code;
			});
			if (index === -1) {
				return;
			}
			languages.splice(index, 1);
		} else {
			// Add
			languages.push(code);
		}

		const data = {
			...pageSessionStorage,
			languages: languages,
		} as PublicationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	//#region Pagination
	const changePage = (page: number) => {
		const data = {
			...pageSessionStorage,
			page: page,
		} as PublicationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const onPaginationPreviousClick = () => {
		if (!pageSessionStorage.searchResponse?.results) return;

		let newPageNr = pageSessionStorage.searchResponse.page
			? pageSessionStorage.searchResponse.page
			: 1;
		if (newPageNr > 0) {
			changePage(newPageNr - 1);
		}
	};

	const onPaginationNextClick = () => {
		if (!pageSessionStorage.searchResponse?.results) return;

		let newPageNr = pageSessionStorage.searchResponse.page
			? pageSessionStorage.searchResponse.page
			: 1;
		if (newPageNr < pageSessionStorage.searchResponse.totalPages) {
			changePage(newPageNr + 1);
		}
	};
	//#endregion

	// #region Space calculation

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_areas: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (pageSessionStorage.selectedPanelId === 'explore') {
		if (topItems.length > 0) {
			lastBlockType = getLastItemBlockType(
				topItems,
				themeContext,
				disableCustomHeadingLogic
			);
			blockTypeBefore_areas = lastBlockType;
			blockTypeBefore_bottomItems = lastBlockType;
		}
		if (areas.length > 0) {
			lastBlockType = BlockType.SectionBanner;
			blockTypeBefore_bottomItems = lastBlockType;
		}
	} else {
		lastBlockType = BlockType.Element;
	}

	const spaceBefore_areas = getSpaceToAddBefore(
		blockTypeBefore_areas,
		BlockType.SectionBanner,
		themeContext
	);

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	//#endregion

	return (
		<Space
			top={themeContext.spacing.getPageTopPadding()}
			bottom={spaceAfterLast}
		>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>
			</Grid>

			<Space top={themeContext.spacing.getElement()}>
				<Grid paddingTop={false} paddingBottom={false}>
					<Tab
						onSelect={onSelectedTab}
						{...tabPanelProps}
						defaultTabId={pageSessionStorage.selectedPanelId}
					></Tab>
				</Grid>
			</Space>

			<div>
				<TabPanel
					panelId="explore"
					selected={pageSessionStorage.selectedPanelId === 'explore'}
				>
					<EpiFragments
						epi={epi}
						headingLevel={2}
						fragments={topItems}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>

					{areas.length > 0 && (
						<Space top={spaceBefore_areas}>
							<LinkTableList
								theme={PuffTheme.BrownLight}
								headingLevel={2}
								heading={publicationsAreaLabel}
								items={areas}
								showMoreLabel={showMoreLabel}
							></LinkTableList>
						</Space>
					)}

					{bottomItems.length > 0 && (
						<EpiFragments
							previousBlockType={blockTypeBefore_bottomItems}
							epi={epi}
							headingLevel={2}
							fragments={bottomItems}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						/>
					)}
				</TabPanel>

				<TabPanel
					panelId="search"
					selected={pageSessionStorage.selectedPanelId === 'search'}
				>
					<Grid paddingTop={false} paddingBottom={false}>
						<SearchInputForm
							label={searchInputLabel}
							query={
								pageSessionStorage.searchResponse
									? pageSessionStorage.searchResponse.query
									: ''
							}
							onSubmit={handleSubmit}
						>
							<InlineControlls>
								<FieldsetInline>
									{categories.map((catItem, index) => {
										return (
											<CategoryDropdown
												id={`cat-${index}`}
												selectedId={
													pageSessionStorage.categories[index.toString()]
												}
												key={index}
												category={catItem}
												onSelectChanged={(id) => onCategorySelected(index, id)}
											></CategoryDropdown>
										);
									})}
								</FieldsetInline>

								<FieldsetInline>
									<DateRangeDropdown
										fromLabel={selectableYears.startYearName}
										toLabel={selectableYears.endYearName}
										onYearRangeChanged={onYearRangeChanged}
										label={selectableYears.name}
										selectedRange={pageSessionStorage.numberRange}
										numberRange={{
											from: selectableYears.startYear,
											to: selectableYears.endYear,
										}}
										flexRow={true}
									></DateRangeDropdown>
								</FieldsetInline>

								<FieldsetInline>
									<FieldsetContainer>
										<legend aria-label={includeLabel}>{includeLabel}</legend>

										<CheckboxesInline>
											{languages.items.map((item, index) => {
												return (
													<Checkbox
														aria-label={item.name}
														key={index}
														checked={pageSessionStorage.languages.includes(
															item.code
														)}
														onCheckedChanged={(value: boolean) => {
															onLanguageCheckedChanged(item.code, value);
														}}
													>
														{item.name}
													</Checkbox>
												);
											})}
										</CheckboxesInline>
									</FieldsetContainer>
								</FieldsetInline>
							</InlineControlls>
						</SearchInputForm>

						{pageSessionStorage.searchResponse && (
							<Space top={themeContext.spacing.getElement()}>
								<ListHeader
									headingLevel={2}
									heading={formatText(
										displayedPublicationsText,
										pageSessionStorage.searchResponse.results.length,
										pageSessionStorage.searchResponse.numberOfHits
									)}
									showOptions={fixedResults.length > 0}
									onViewChange={onListViewChanged}
									defaultValue={pageSessionStorage.selectedViewType}
								/>
								{fixedResults.length > 0 && (
									<Space top={themeContext.spacing.space3}>
										<FragmentPuffList
											options={{
												insideCell: false,
												insideInnerGrid: false,
												insideGrid: true,
												headingLevel: 2, // TODO:
												view: pageSessionStorage.selectedViewType,
												themeContext: themeContext,
											}}
											showMoreLabel={showMoreLabel}
											items={fixedResults}
											disableCustomHeadingLogic={disableCustomHeadingLogic}
										></FragmentPuffList>
									</Space>
								)}

								{pageSessionStorage.searchResponse.totalPages > 1 && (
									<Space top={themeContext.spacing.space3}>
										<Pagination
											onPreviousSlide={onPaginationPreviousClick}
											inactivateBackwardButton={
												pageSessionStorage.searchResponse.page <= 1
											}
											onNextSlide={onPaginationNextClick}
											inactivateForwardButton={
												pageSessionStorage.searchResponse.page >=
												pageSessionStorage.searchResponse.totalPages
											}
										/>
									</Space>
								)}
							</Space>
						)}
					</Grid>
				</TabPanel>
			</div>
		</Space>
	);
};

export default NVsePublicationsListingPage;

import { ThemeProps } from 'components/Banner/Banner';
import { Grid } from 'components/Boilerplate/Grid';
import Link from 'components/Boilerplate/Link';
import { getThemeSettings } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const LinkTableListStyle = styled.div<ThemeProps>`
	${(p) => p.themeName && getThemeSettings(p.themeName, p.theme)};
`;

export const GridWrapper = styled(Grid)`
	${mediaQueries.phone} {
		grid-gap: ${props => props.theme.spacing.space1}rem;
	}
`;

export const AreaContainer = styled(Link)`
	display: block;
	${props => props.theme.typography.style4.getStyle()};		

	padding: ${props => props.theme.spacing.space3}rem ${props => props.theme.spacing.space4}rem;

	${mediaQueries.phone} {
		padding: ${props => props.theme.spacing.space1}rem ${props => props.theme.spacing.space2}rem;
	}
	background-color: ${props => props.theme.colors.white};
`;

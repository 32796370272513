import { BannerLinksListStyle } from 'components/Banners/BannerLinks/BannerLinks.styles';
import Link from 'components/Boilerplate/Link';
import { Heading } from 'components/Typography/Typography';
import { LinkType } from 'pages/sharedModelTypes';
import React, { useEffect, useState } from 'react';
import { IndexHeading, IndexSection } from './IndexPanel.styles';

/**
 * IndexPanel
 */
type IndexType = {
	name: string;
	items: LinkType[];
};

type IndexPanelProps = {
	items: LinkType[];
	columns?: number;
};

export const IndexPanel: React.FC<IndexPanelProps> = ({ items, columns }) => {
	const [indexList, setindexList] = useState<IndexType[]>([]);

	useEffect(() => {
		let listing: IndexType[] = [];
		let indexChar: string;
		let currentIndex: IndexType;

		const sorted = items.slice().sort((a, b) => {
			if (a.heading < b.heading) {
				return -1;
			}
			if (a.heading > b.heading) {
				return 1;
			}
			return 0;
		});

		sorted.forEach((item: LinkType) => {
			indexChar = item.heading[0];

			if (!currentIndex || currentIndex.name !== indexChar) {
				currentIndex = {
					name: indexChar,
					items: [],
				} as IndexType;
				listing.push(currentIndex);
			}
			currentIndex.items.push(item);
		});

		setindexList(listing);
	}, [items]);

	return (
		<>
			<BannerLinksListStyle columns={columns}>
				{indexList.map((item, index) => {
					return (
						<li key={index}>
							<IndexHeading>{item.name}</IndexHeading>
							<IndexSection>
								{item.items.map((link, index) => {
									return (
										<li key={index}>
											<Link
												showLinkIcon={false}
												to={link.url}
												external={link.isFile}
											>
												{link.heading}
											</Link>
										</li>
									);
								})}
							</IndexSection>
						</li>
					);
				})}
			</BannerLinksListStyle>
		</>
	);
};

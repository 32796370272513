import { H3 } from 'components/Typography';
import styled from 'styled-components';

export const IndexSection = styled.ul`
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	> :last-child {
		margin-bottom: ${props => props.theme.spacing.space4}rem;
	}
`;

export const IndexHeading = styled(H3)`
		${props => props.theme.typography.style12.getStyle()};		
	margin-bottom: ${props => props.theme.spacing.space2}rem;
`;

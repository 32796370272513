/**
 * NVseStatisticsListingPage - styles
 */

import styled from 'styled-components';

export const Div = styled.div``;

export const IndexLabel = styled.h2`
		${props => props.theme.typography.style2.getStyle()};		
`;

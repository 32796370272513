import { LinkType } from 'pages/sharedModelTypes';
import { ContentTypes } from 'types/pages';
import { FragmentListModel, ImageFragmentModel } from './fragments';

declare global {
	interface Window {
		__model: any;
		epi: any;
	}
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace NodeJS {
		interface Global {
			window: any;
			document: any;
			Styled: any;
			React: any;
			ReactDOM: any;
			ReactDOMServer: any;
		}
	}
}

export interface EpiEvent {
	isEditable: false;
	inEditMode: false;
	inPreviewMode: false;
	ready: false;
}

export interface Model {
	head?: ModelHead;
	metadata?: ModelMetadata;
	content?: ContentTypes;
	navigation?: ModelNavigation;
	lang?: Lang;
	quickLinks?: ModelQuickLinks;
	breadcrumbs?: ModelBreadcrumbs;
	search?: ModelSearch;
	localization?: ModelLocalization;
	cookieBar?: ModelCookieBar;
	tracer?: ModelTracer;
	footer?: ModelFooter;
	react: ModelReact | undefined | null;
	html?: any;
	epi?: any;
	globalMessages?: ModelGlobalMessages;
	microsite?: ModelMicrosite;
	logo?: LogoModel;
	appTypeLayout?: AppTypeLayout;
}

export interface LogoModel extends ImageFragmentModel {}

export interface ContentLinkResponse {
	status: number;
	result: ContentTypes;
}

export interface ContentSavedEvent {
	contentLink: string;
	properties: ContentSavedProperty[];
}

export interface ContentSavedProperty {
	name: string;
	successful: boolean;
	validationErrors: any;
	value: string;
}

export interface SessionStorageModel {
	modelType: string;
	id: string;
	data: any;
}

export interface SearchResultModel {
	query: string;
	page: number;
	size: number;
	totalPages: number;
	numberOfHits: number;
	results: any[];
}

export interface ModelMicrosite {
	theme: ModelMicrositeStyles;
	headerLogo: {
		desktop: ImageFragmentModel;
		mobile: ImageFragmentModel;
	};
	footerLogo: {
		desktop: ImageFragmentModel;
		mobile: ImageFragmentModel;
	};
}

export interface ModelMicrositeStyles {
	colors: {
		primaryColor: string;
		secondaryColor: string;
		tertiaryColor: string;
		quaternaryColor: string;
	};
	textPalette: {
		primary: string;
		secondary: string;
	};
	typographyType: {
		primaryFontFamily: string;
		secondaryFontFamily: string;
	};
}
export interface ModelBreadcrumbs {
	breadcrumbs: ModelBreadcrumb[];
}

export interface ModelLocalization {
	culture: string;
	translations: any;
}

export interface ModelCookieBar {
	heading: string;
	text: any;
	buttonText: string;
	readMoreText: string;
	readMoreUrl: string;
}
export interface DefinedLinkType {
	type: string;
	link: LinkType;
	icon?: ImageFragmentModel;
}

export interface CustomerService {
	heading: string;
	text: string;
	textAsModel: FragmentListModel;
	customerServiceLink: LinkType;
	contactInformation?: MicrositeInfo[];
	footerLinkList: FooterLinkList;
	socialLinks: SocialLinks;
}
export interface MicrositeInfo {
	key: string;
	value: string;
}
export interface FooterLinkList {
	heading: string;
	links: LinkType[];
}
export interface SocialLinks {
	heading: string;
	links: DefinedLinkType[];
}
export interface ModelFooter {
	footerGroups: ModelFooterSection[];
	customerService: CustomerService;
}

export interface ModelTracer {
	serverUrl: string | null;
	serviceName: string | null;
	environment: string | null;
	enabled: boolean;
	logLevel: string | null;
	traceId: EnvironmentId | null;
	buildDate: string | null | undefined;
	parentId: string | null;
	sample: boolean;
}

export enum EnvironmentId {
	DEV = 'DEV',
	SMOKE = 'SMOKE',
	INT = 'INT',
	PREP = 'PREP',
	PROD = 'PROD',
}

export interface FavIcon {
	src: string;
	sizes: string;
	type: string;
	rel: string;
}

interface ModelMetadata {
	title?: string | null;
	favIcons?: FavIcon[];
	keywords?: string | null;
	description?: string | null;
	googleSiteVerificationTag?: string | null;
	twitter?: {
		card?: string | null;
		title?: string | null;
		description?: string | null;
		image?: string | null;
	};
	openGraph?: {
		title?: string | null;
		siteName?: string | null;
		type?: string | null;
		url?: string | null;
		description?: string | null;
		image?: string | null;
	};
	sdg?: {
		includeSDGMetaTags: boolean;
		sdgMetaDataTags: any;
	};
	excludeFromIndex: boolean;
	canonicalUrl?: string;
}

export interface NavigationItem {
	url: string;
	text: string;
	isActive: boolean;
	style?: number;
	isRedirect: boolean;
	children: NavigationItem[];
}
export interface ModelNavigation {
	home: NavigationItem;
	items: NavigationItem[];
}

export interface ModelQuickLinksItem {
	heading: string;
	url: string;
	type: string;
}

interface ModelQuickLinks {
	skipToContent?: string;
	quickLinks: ModelQuickLinksItem[];
}

export interface ModelBreadcrumb {
	url: string;
	text: string;
	isActive: boolean;
}

interface FooterSectionHead {
	heading: string;
	url: string;
}

export interface FooterSectionChildrenItem {
	heading: string;
	url: string;
}

export interface ModelFooterSection {
	head: FooterSectionHead;
	children: FooterSectionChildrenItem[];
}

interface ModelMetadata {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	twitter?: {
		card?: string | null;
		title?: string | null;
		description?: string | null;
		image?: string | null;
	};
	openGraph?: {
		title?: string | null;
		siteName?: string | null;
		type?: string | null;
		url?: string | null;
		description?: string | null;
		image?: string | null;
	};
}

interface ModelHead {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	openGraphTitle?: string | null;
	openGraphImageUrl?: string | null;
	openGraphDescription?: string | null;
	twitterTitle?: string | null;
	twitterDescription?: string | null;
	twitterImageUrl?: string | null;
}

interface ModelSearch {
	searchPageUrl?: string;
	searchLabel?: string;
}

export interface ModelReact {
	containerId: string;
	clientOnly: boolean;
	siteBaseUrl: string;
	apiUrl: string;
	apiEditUrl: string;
	renderingState: RenderingStates;
}

export enum RenderingStates {
	ServerSide = 1,
	Hydrate,
	ClientSide,
}

export interface Lang {
	menuHeaderIsItem: boolean;
	items: LangItems[];
}

interface LangItems {
	url: string;
	code: string;
	active: boolean;
	text: string;
}

export interface ModelGlobalMessages {
	messages: ModelGlobalMessage[];
	showMoreText: string | undefined;
	showLessText: string | undefined;
	linkText: string | undefined;
}

export interface ModelGlobalMessage {
	heading: string;
	text: any;
	link: string;
	showMoreText: string | undefined;
	showLessText: string | undefined;
}

interface AppTypeLayout {
	name: string;
}

/**
 * MicrositeListingPage
 */

import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import React, { useContext, useEffect, useState } from 'react';
import {
	Puff,
	MicrositeListingPageModel,
} from './MicrositeListingPage.model';
import { useSelector } from 'react-redux';
import { selectLocalization } from 'store/modules/model';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { ThemeContext } from 'styled-components';
import { ShowMoreButton } from 'components/Panels/ShowMoreButton';
import { GetStandardPuffElement } from 'components/Boilerplate/EpiFragments/fragments/StandardPuffModel';
import { ViewType } from 'types/enums';
import { Options } from 'components/Boilerplate/EpiFragments/EpiFragments';
import { ListItemsStyle } from './MicrositeListingPage.styles';

const MicrositeListingPage: React.FC<MicrositeListingPageModel> = ({
	heading,
	preamble,
	puffs,
	showMoreLabel,
	puffNavigationListSizeOnLoad,
	_properties = {},
}) => {
	const { items } = puffs;
	const localization = useSelector(selectLocalization);
	const themeContext = useContext(ThemeContext);
	const [showItemsCount, setShowItemsCount] = useState<number>(0);
	const [showMoreButton, setShowMoreButton] = useState<boolean>(false);
	const [filterdItems, setFilterdItems] = useState<Puff[]>([]);

	useEffect(() => {
		let itemsToShow = puffNavigationListSizeOnLoad
			? puffNavigationListSizeOnLoad
			: items.length;
		if (itemsToShow > items.length) {
			itemsToShow = items.length;
		}

		setShowItemsCount(itemsToShow);
	}, [puffNavigationListSizeOnLoad, items]);

	useEffect(() => {
		setShowMoreButton(showItemsCount < items.length);
		setFilterdItems(items.slice(0, showItemsCount));
	}, [items, showItemsCount]);

	const options = {
		insideGrid: false,
		insideInnerGrid: false,
		insideCell: false,
		themeContext: themeContext,
		view: puffs.viewType,
	} as Options;

	return (
		<>
			<Space
				top={themeContext.spacing.getPageTopPadding()}
				bottom={themeContext.spacing.getPageTopPadding()}
			>
				<Grid paddingTop={false} paddingBottom={false}>
					<Space bottom={themeContext.spacing.getSection()}>
						<GrantsHeader
							columns={8}
							headingLevel={1}
							preamble={preamble}
							heading={heading}
							heading_htmlAttributes={_properties?.heading}
						></GrantsHeader>
					</Space>
					{filterdItems.length > 0 && (
						<Space
							top={themeContext.spacing.space3}
							stack={themeContext.spacing.space2}
						>
							<ListItemsStyle
								cardView={options.view === ViewType.Card ? true : false}
							>
								{filterdItems.map((item, index) => {
									// StandardPuff or PuffListItem
									return GetStandardPuffElement(item, { ...options, index });
								})}
							</ListItemsStyle>
						</Space>
					)}
				</Grid>
				{showMoreButton && (
					<Grid paddingTop={false} paddingBottom={false}>
						<Space top={themeContext.spacing.getSection()}>
							<ShowMoreButton
								header={showMoreLabel}
								onClick={() => setShowItemsCount(items.length)}
							></ShowMoreButton>
						</Space>
					</Grid>
				)}
			</Space>
		</>
	);
};

export default MicrositeListingPage;

import { useEffect, useRef, useState } from 'react';
import { AppProps, getLocation, routePatterns } from 'App';
import { GlobalStyle } from 'theme/global';
import Breadcrumbs, {
	BreadcrumbItemtype,
} from 'components/Breadcrumbs/Breadcrumbs';
import MegaMenu from 'components/MegaMenu';
import MenuMobile from 'components/MenuMobile';
import { ThemeProvider } from 'styled-components';
import { ThemeDesktop, ThemeMobile } from 'theme/theme';
import EnvironmentToaster from 'components/EnvironmentToaster';
import CookieBar from 'components/CookieBar';
import GlobalMessage from 'components/GlobalMessage/GlobalMessage';
import SiteHeader from 'components/SiteHeader';
import { BreadcrumbsContainer } from 'components/SiteHeader/SiteHeader.styles';
import { Grid } from 'components/Boilerplate/Grid';
import { Route, Switch } from 'react-router-dom';
import { RouteHandler } from 'routeHandler';
import SiteFooter from 'components/SiteFooter';
import Meta from 'components/Boilerplate/Meta';

const NVseApp = ({
	isNarrowDisplay,
	Router,
	tracer,
	cookieBar,
	globalMessages,
	breadcrumbs,
	navigation,
	quickLinks,
	skipToContent,
	lang,
	footer,
	pageContent,
	logo,
}: AppProps) => {
	const location = getLocation(pageContent);
	const menuButtonRef = useRef<any>(null);

	const [menuComponent, setMenuComponent] = useState<any>();
	const [fixedBreadcrumbs, setFixedBreadcrumbs] = useState<
		BreadcrumbItemtype[]
	>([]);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);

	useEffect(() => {
		const fixed = breadcrumbs
			? breadcrumbs.filter((item) => {
					return item.url;
			  })
			: [];

		setFixedBreadcrumbs(fixed);
	}, [breadcrumbs]);

	useEffect(() => {
		const onCloseMenuEvent = (focusOrigin: boolean) => {
			if (focusOrigin) {
				if (menuButtonRef && menuButtonRef.current) {
					menuButtonRef.current.focus();
				}
			}
			setIsMenuOpen(false);
		};

		const menuRoot = navigation?.items ? navigation?.items : [];

		let menuComponent2 = (
			<MegaMenu items={menuRoot} onClose={onCloseMenuEvent} />
		);

		if (isNarrowDisplay && menuRoot) {
			menuComponent2 = (
				<MenuMobile onClose={onCloseMenuEvent} items={menuRoot} />
			);
		}

		setMenuComponent(menuComponent2);
	}, [navigation?.items, isNarrowDisplay]);

	const onOpenMenu = (isOpen: boolean) => {
		setIsMenuOpen(isOpen);
	};

	const onOpenSearchMenu = (isOpen: boolean) => {
		setIsSearchOpen(isOpen);
	};

	return (
		<ThemeProvider theme={isNarrowDisplay ? ThemeMobile : ThemeDesktop}>
			<Router location={location} context={{}}>
				<a className="skip-to-content-link" href="#main">
					{skipToContent ?? 'Gå till innehåll'}
				</a>

				{tracer && tracer.environment && tracer.environment !== 'PROD' && (
					<EnvironmentToaster
						env={tracer?.environment}
						buildDate={tracer.buildDate}
					/>
				)}

				{cookieBar && (
					<CookieBar
						text={cookieBar.text}
						buttonText={cookieBar.buttonText}
						readMoreText={cookieBar.readMoreText}
						readMoreUrl={cookieBar.readMoreUrl}
					/>
				)}

				{globalMessages &&
					globalMessages.messages.map((message, index) => {
						return (
							<GlobalMessage
								key={index}
								index={index}
								heading={message.heading}
								link={message.link}
								text={message.text}
								showMoreText={globalMessages.showMoreText}
								showLessText={globalMessages.showLessText}
								linkText={globalMessages.linkText}
							/>
						);
					})}

				<SiteHeader
					ref={menuButtonRef}
					home={navigation?.home}
					quickLinks={quickLinks}
					lang={lang}
					isPopupMenuOpen={false}
					onOpenSearchMenu={onOpenSearchMenu}
					onOpenMenu={onOpenMenu}
					isSearchOpen={isSearchOpen}
					isMenuOpen={isMenuOpen}
					logo={logo}
				/>

				{pageContent?.modelType !== 'NVseStartPage' &&
					fixedBreadcrumbs.length > 0 && (
						<BreadcrumbsContainer>
							<Grid paddingTop={false} paddingBottom={false}>
								<Breadcrumbs items={fixedBreadcrumbs} />
							</Grid>
						</BreadcrumbsContainer>
					)}

				<Switch>
					{routePatterns.map((routePattern) => (
						<Route key={routePattern} path={routePattern}>
							<RouteHandler />
						</Route>
					))}
				</Switch>
				{footer && (
					<SiteFooter
						footerGroups={footer.footerGroups}
						customerService={footer.customerService}
						disableCustomHeadingLogic={
							pageContent?.disableCustomHeadingLogic ?? false
						}
					/>
				)}

				{isMenuOpen && menuComponent}

				<GlobalStyle isOpen={isMenuOpen || isSearchOpen ? true : false} />
			</Router>
		</ThemeProvider>
	);
};

export default NVseApp;

import React, { useContext, useEffect, useState } from 'react';
import Space from 'components/Boilerplate/Space';
import { NVseStatisticsListingPageModel } from './NVseStatisticsListingPage.model';
import { Grid } from 'components/Boilerplate/Grid';
import Tab, { TabPanel, TabProps } from 'components/Tab/Tab';
import { IndexLabel } from './NVseStatisticsListingPage.styles';
import { LinkType, PuffTheme } from 'pages/sharedModelTypes';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { useDispatch, useSelector } from 'react-redux';
import { SearchResultModel } from 'types/epi';
import { selectReact } from 'store/modules/react';
import {
	fetchSearchResult,
	selectLocalization,
	setSessionStorage,
} from 'store/modules/model';
import { ApiPath } from 'api/epiApi';
import {
	CheckboxesInline,
	FieldsetInline,
	InlineControlls,
} from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage.styles';
import Checkbox from 'components/Checkbox';
import { buildQueryString, translate } from 'utils/helper-utils';
import { LabelContainer, LabelWrapper } from 'components/Panels/Panels.styles';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { LinkTableList } from 'components/Panels/LinkTableList';
import { IndexPanel } from 'components/Panels/IndexPanel';
import { SearchInputForm } from 'components/Panels/SearchInputForm';
import { CategoryDropdown } from 'pages/NVseRegulationsListingPage/CategoryDropdown';
import {
	createPageSessionStorage,
	usePageSessionStorage,
} from 'hooks/useSessionStorage';
import { ThemeContext } from 'styled-components';

type StatisticsSearchResultItemModel = {
	heading: string;
	url: string;
};
type SearchStatisticsParams = {
	query?: string;
	catId?: string;
	includeOfficialStats?: boolean;
};

interface CategoryList {
	[key: string]: string;
}

type StatisticsListingPageSessionModel = {
	selectedPanelId: string;
	includeOfficialStats: boolean;
	categories: CategoryList;
	page: number | null;
	query: string;
	searchResponse: SearchResultModel | null;
};

/**
 * # Listningssida för statistik 
 * Modeltype:<code>NVseStatisticsListingPage</code>
 * 
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2091384865/NVseStatisticsListingPage)
 * 
 * Landningssida för webbplatsens statistiksidor
 */
const NVseStatisticsListingPage: React.FC<NVseStatisticsListingPageModel> = ({
	id,
	modelType,
	heading,
	preamble,
	exploreLabel,
	searchLabel,
	officialStatisticsLabel,
	searchInputLabel,
	categories,
	allStatisticsHeading,
	topItems,
	statisticsAreaLabel,
	areas,
	bottomItems,
	epi,
	_properties = {},
	disableCustomHeadingLogic
}) => {
	const localization = useSelector(selectLocalization);
	const themeContext = useContext(ThemeContext);

	const includeLabel = translate(
		'/frontend/pages/includeonly',
		'Include only',
		localization
	);
	const showMoreLabel = 'Visa alla'; // TODO: hardcoded language

	const initialSessionDate: StatisticsListingPageSessionModel = {
		selectedPanelId: 'explore',
		includeOfficialStats: false,
		categories: {},
		page: null,
		query: '',
		searchResponse: null,
	};
	const dispatch = useDispatch();
	const { apiUrl } = useSelector(selectReact);

	const [fixedResults, setFixedResults] = useState<LinkType[]>([]);
	const pageSessionStorage = usePageSessionStorage<StatisticsListingPageSessionModel>(
		modelType,
		id,
		initialSessionDate
	);

	const tabPanelProps = {
		label: 'Sidor',
		defaultTabId: pageSessionStorage.selectedPanelId,
		autoSelect: false,
		items: [
			{
				heading: exploreLabel,
				id: 'explore',
				iconName: null,
			},
			{
				heading: searchLabel,
				id: 'search',
				iconName: 'search',
			},
		],
	} as TabProps;

	useEffect(() => {
		const getFixedSearchResult = (searchResponse: SearchResultModel) => {
			if (searchResponse) {
				return searchResponse.results.map(
					(item: StatisticsSearchResultItemModel) => {
						return {
							heading: item.heading,
							url: item.url,
						} as LinkType;
					}
				);
			}
			return [];
		};

		if (pageSessionStorage.searchResponse) {
			setFixedResults(getFixedSearchResult(pageSessionStorage.searchResponse));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSessionStorage]);

	useEffect(() => {
		// Make a search first time we enter the TAB.
		if (
			pageSessionStorage.selectedPanelId === 'search' &&
			pageSessionStorage.searchResponse === null
		) {
			executeQuery(pageSessionStorage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSessionStorage.selectedPanelId]);

	const handleSubmit = (query: string) => {
		const data = {
			...pageSessionStorage,
			page: 1,
			query,
		} as StatisticsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const executeQuery = (state: StatisticsListingPageSessionModel) => {
		const urlQuery = buildQueryFromModel(state);

		// TODO: Switch to real API
		// dispatch(fetchSearchResult(modelType, id, "searchResponse", "https://8167b974-87ff-4a2b-93a0-e92912c37eef.mock.pstmn.io", ApiPath.NVseStatisticsListingPageDemo, urlQuery));

		dispatch(
			fetchSearchResult(
				modelType,
				id,
				'searchResponse',
				apiUrl,
				ApiPath.NVseStatisticsListingPage,
				urlQuery
			)
		);
	};

	const buildQueryFromModel = (
		pageState: StatisticsListingPageSessionModel
	): string => {
		const queryParams = {} as SearchStatisticsParams;

		if (pageState.query) {
			queryParams.query = pageState.query;
		}

		queryParams.includeOfficialStats = pageState.includeOfficialStats;

		// Special handling for categories, the API can only handle one category at a time
		if (Object.keys(pageState.categories).length) {
			// Pick first category
			queryParams.catId =
				pageState.categories[Object.keys(pageState.categories)[0]];
		}

		return buildQueryString(queryParams);
	};

	const onSelectedTab = (panelId: string) => {
		const data = {
			...pageSessionStorage,
			selectedPanelId: panelId,
		} as StatisticsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));
	};

	const onCategorySelected = (index: Number, catId: string) => {
		const newCategories = { ...pageSessionStorage.categories };
		if (catId) {
			newCategories[index.toString()] = catId;
		} else if (newCategories[index.toString()]) {
			delete newCategories[index.toString()];
		}

		const data = {
			...pageSessionStorage,
			page: 1,
			categories: newCategories,
		} as StatisticsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const officialStatisticsCheckedChanged = (value: boolean) => {
		const data = {
			...pageSessionStorage,
			page: 1,
			includeOfficialStats: value,
		} as StatisticsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	// #region Space calculation

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_areas: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (pageSessionStorage.selectedPanelId === 'explore') {
		if (topItems.length > 0) {
			lastBlockType = getLastItemBlockType(topItems, themeContext, disableCustomHeadingLogic);
			blockTypeBefore_areas = lastBlockType;
			blockTypeBefore_bottomItems = lastBlockType;
		}

		if (areas.length > 0) {
			lastBlockType = BlockType.SectionBanner;
			blockTypeBefore_bottomItems = lastBlockType;
		}

		if (bottomItems.length > 0) {
			lastBlockType = getLastItemBlockType(bottomItems, themeContext, disableCustomHeadingLogic);
		}
	} else {
		lastBlockType = BlockType.Element;
	}

	const spaceBefore_areas = getSpaceToAddBefore(
		blockTypeBefore_areas,
		BlockType.SectionBanner,
		themeContext
	);
	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	//#endregion

	return (
		<Space top={themeContext.spacing.getPageTopPadding()} bottom={spaceAfterLast}>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>
			</Grid>

			<Space top={themeContext.spacing.getElement()}>
				<Grid paddingTop={false} paddingBottom={false}>
					<Tab
						onSelect={onSelectedTab}
						{...tabPanelProps}
						defaultTabId={pageSessionStorage.selectedPanelId}
					></Tab>
				</Grid>
			</Space>

			<div>
				<TabPanel
					panelId="explore"
					selected={pageSessionStorage.selectedPanelId === 'explore'}
				>
					{topItems.length > 0 && (
						<EpiFragments epi={epi} headingLevel={2} fragments={topItems} disableCustomHeadingLogic={disableCustomHeadingLogic}/>
					)}

					<Space top={spaceBefore_areas}>
						<LinkTableList
							theme={PuffTheme.BrownLight}
							headingLevel={2}
							heading={statisticsAreaLabel}
							items={areas}
							showMoreLabel={showMoreLabel}
						></LinkTableList>
					</Space>

					{bottomItems.length > 0 && (
						<EpiFragments
							previousBlockType={blockTypeBefore_bottomItems}
							epi={epi}
							headingLevel={2}
							fragments={bottomItems}
							disableCustomHeadingLogic={disableCustomHeadingLogic}
						/>
					)}
				</TabPanel>

				<TabPanel
					panelId="search"
					selected={pageSessionStorage.selectedPanelId === 'search'}
				>
					<Grid paddingTop={false} paddingBottom={false}>
						<SearchInputForm
							label={searchInputLabel}
							query={
								pageSessionStorage.searchResponse
									? pageSessionStorage.searchResponse.query
									: ''
							}
							onSubmit={handleSubmit}
						>
							<InlineControlls>
								<FieldsetInline>
									{categories.map((catItem, index) => {
										return (
											<CategoryDropdown
												id={`cat-${index}`}
												selectedId={
													pageSessionStorage.categories[index.toString()]
												}
												key={index}
												category={catItem}
												onSelectChanged={(id) => onCategorySelected(index, id)}
											></CategoryDropdown>
										);
									})}
								</FieldsetInline>

								<FieldsetInline>
									<LabelContainer>
										<LabelWrapper>
											<legend>{includeLabel}</legend>
										</LabelWrapper>
										<CheckboxesInline>
											<Checkbox
												checked={pageSessionStorage.includeOfficialStats}
												onCheckedChanged={(value: boolean) => {
													officialStatisticsCheckedChanged(value);
												}}
											>
												{officialStatisticsLabel}
											</Checkbox>
										</CheckboxesInline>
									</LabelContainer>
								</FieldsetInline>
							</InlineControlls>
						</SearchInputForm>

						{pageSessionStorage.searchResponse && fixedResults.length > 0 && (
							<Space top={themeContext.spacing.getElement()}>
								<Grid paddingTop={false} paddingBottom={false}>
									<IndexLabel>{allStatisticsHeading}</IndexLabel>
									<IndexPanel items={fixedResults}></IndexPanel>
								</Grid>
							</Space>
						)}
					</Grid>
				</TabPanel>
			</div>
		</Space>
	);
};

export default NVseStatisticsListingPage;

/**
 * Typography
 */

import Link from 'components/Boilerplate/Link';
import React from 'react';
import styled, { css } from 'styled-components';
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Button,
	P,
	Preamble,
	Span,
	Div,
	ScreenReaderText,
	styleForH1,
	styleForH2,
	styleForH3,
	styleForH4,
	styleForH5,
	styleForP,
} from './Typography.styles';

export type Tags =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'button'
	| 'p'
	| 'preamble'
	| 'span'
	| 'div'
	| 'screenReader';

export interface TypographyProps {
	/** The text to render (if no children are present) */
	text?: string;

	/** The html-tag/Component that will be outputted */
	as: Tags;

	/** The style that will be used (instead of the tag default style) */
	styleAs?: any;

	/** Pass optional classnames for the html */
	className?: string;

	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any;
}

const tagMapper = {
	h1: H1,
	h2: H2,
	h3: H3,
	h4: H4,
	h5: H5,
	h6: H6,
	preamble: Preamble,
	button: Button,
	p: P,
	a: Link,
	span: Span,
	div: Div,
	screenReader: ScreenReaderText,
};

/** Typography component render texts */
const Typography: React.FC<TypographyProps> = ({
	children,
	text,
	styleAs,
	as,
	className = '',
	...htmlAttributes
}) => {
	const content = children || text;
	const Component = styleAs || tagMapper[as];
	const ComponentAs: any = as;

	if (!content) {
		return null;
	}

	if (styleAs) {
		return (
			<Component as={ComponentAs} className={className} {...htmlAttributes}>
				{content}
			</Component>
		);
	} else {
		return (
			<Component className={className} {...htmlAttributes}>
				{content}
			</Component>
		);
	}
};

export default Typography;

type HeadingProps = {
	level: number | undefined;
	styleLevel?: number | undefined;
	color?: string;
	zeroBottom?: boolean;
	htmlAttributes?: any;
	id?: string;
	className?: any;
};

export const Heading: React.FC<HeadingProps> = ({
	level,
	styleLevel,
	color,
	children,
	zeroBottom = false,
	id,
	htmlAttributes = {},
	className,
}) => {
	if (!children) {
		return null;
	}

	const safeLevel =
		level === 0 || level === undefined || level >= headerThemeTagMapper.length
			? 6
			: level;

	const Component = headerThemeTagMapper[safeLevel - 1];

	const safeStyleLevel =
		styleLevel === undefined || styleLevel === null ? safeLevel : styleLevel;
	return (
		<Component
			id={id}
			color={color}
			level={level}
			styleLevel={safeStyleLevel}
			zeroBottom={zeroBottom}
			className={className}
			{...htmlAttributes}
		>
			{children}
		</Component>
	);
};

const getTypographyStyle = (
	styleLevel: number | undefined,
	color: string | undefined,
	zeroBottom: boolean | undefined
) => {
	const safeStyleLevel =
		styleLevel && styleLevel > 0 && styleLevel < 7 ? styleLevel : 1;

	if (styleLevel && styleLevel > 6) {
		switch (styleLevel) {
			default:
			case 7:
				return css`
					${(props) => props.theme.typography.style7.getStyle()};
					${color && `color: ${color};`}
					${zeroBottom && `margin-bottom:0px;`}
				`;
			case 8:
				return css`
					${(props) => props.theme.typography.style8.getStyle()};
					${color && `color: ${color};`}
					${zeroBottom && `margin-bottom:0px;`}
				`;
			case 9:
				return css`
					${(props) => props.theme.typography.style9.getStyle()};
					${color && `color: ${color};`}
					${zeroBottom && `margin-bottom:0px;`}
				`;
			case 10:
				return css`
					${(props) => props.theme.typography.style10.getStyle()};
					${color && `color: ${color};`}
					${zeroBottom && `margin-bottom:0px;`}
				`;
			case 11:
				return css`
					${(props) => props.theme.typography.style11.getStyle()};
					${color && `color: ${color};`}
					${zeroBottom && `margin-bottom:0px;`}
				`;
			case 12:
				return css`
					${(props) => props.theme.typography.style12.getStyle()};
					${color && `color: ${color};`}
					${zeroBottom && `margin-bottom:0px;`}
				`;
		}
	}

	return css`
		${headerStyleMapper[safeStyleLevel - 1]};
		${color && `color: ${color};`}
		${zeroBottom && `margin-bottom:0px;`}
	`;
};

/**
 * Use when invalid heading level 0 or > 6
 */

const HeaderStyle1 = styled.h1<HeadingProps>`
	${({ styleLevel, color, zeroBottom }) =>
		getTypographyStyle(styleLevel, color, zeroBottom)};
`;
const HeaderStyle2 = styled.h2<HeadingProps>`
	${({ styleLevel, color, zeroBottom }) =>
		getTypographyStyle(styleLevel, color, zeroBottom)};
`;
const HeaderStyle3 = styled.h3<HeadingProps>`
	${({ styleLevel, color, zeroBottom }) =>
		getTypographyStyle(styleLevel, color, zeroBottom)};
`;
const HeaderStyle4 = styled.h4<HeadingProps>`
	${({ styleLevel, color, zeroBottom }) =>
		getTypographyStyle(styleLevel, color, zeroBottom)};
`;
const HeaderStyle5 = styled.h5<HeadingProps>`
	${({ styleLevel, color, zeroBottom }) =>
		getTypographyStyle(styleLevel, color, zeroBottom)};
`;
const HeaderStyleAsP = styled.p<HeadingProps>`
	${({ styleLevel, color, zeroBottom }) =>
		getTypographyStyle(styleLevel, color, zeroBottom)};
`;

const headerThemeTagMapper = [
	HeaderStyle1,
	HeaderStyle2,
	HeaderStyle3,
	HeaderStyle4,
	HeaderStyle5,
	HeaderStyleAsP,
];

const headerStyleMapper = [
	styleForH1,
	styleForH2,
	styleForH3,
	styleForH4,
	styleForH5,
	styleForP,
];

/**
 * GuidanceFilter
 */
import cloneDeep from 'lodash/cloneDeep';
import Checkbox from 'components/Checkbox';
import { LabelContainer, LabelWrapper } from 'components/Panels/Panels.styles';
import { SearchInputForm } from 'components/Panels/SearchInputForm';
import Pills, { PillItem } from 'components/Pills/Pills';
import { CategoryList } from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage';
import { Categories } from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage.model';
import {
	CheckboxesInline,
	FieldsetInline,
	InlineControlls,
} from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage.styles';
import { CategoryDropdown } from 'pages/NVseRegulationsListingPage/CategoryDropdown';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getGuidanceFilterState,
	selectGuidanceFilterState,
	selectLocalization,
} from 'store/modules/model';
import { GuidancePuffModel } from 'types/fragments';
import { translate } from 'utils/helper-utils';
import { useHistory } from 'react-router-dom';

export type GuidanceFilterState = {
	query: string;
	categories: CategoryList;
	includeOnly: boolean;
	pills: PillItem[];
	page: number | null;
};

type GuidanceFilterProps = {
	guidanceItems: GuidancePuffModel[];
	onFilterChanged?: (guides: GuidancePuffModel[]) => void;
	searchInputLabel?: string | null;
	supervisoryGuidanceLabel: string;
	categories: Categories[];
	guidanceLabel?: string;
};

const GuidanceFilter = ({
	guidanceItems,
	searchInputLabel,
	supervisoryGuidanceLabel,
	categories,
	onFilterChanged,
}: GuidanceFilterProps) => {
	const guidanceFilterStoraged = useSelector(selectGuidanceFilterState);
	const localization = useSelector(selectLocalization);
	const includeLabel = translate(
		'/frontend/pages/includeonly',
		'Include only',
		localization
	);
	const dispatch = useDispatch();
	const { action } = useHistory();

	const [pageState, setPageState] = useState<GuidanceFilterState>({
		query: '',
		includeOnly: false,
		categories: {},
		pills: [],
		page: null,
	} as GuidanceFilterState);

	const userSearchId = '_USER_SEARCH_';
	const searchPrefixLabel = 'Sök'; // TODO: Hardcoded text

	useEffect(() => {
		// Clear input field after search
		const newPageState = { ...pageState, query: '' };
		setPageState(newPageState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageState.query]);

	useEffect(() => {
		// if action is POP (click on go back brosewr btn)
		// recover guidanceFilterStoraged pills and update the state
		if (action === 'POP') {
			if (typeof guidanceFilterStoraged !== 'undefined') {
				setPageState(guidanceFilterStoraged);
				executeQuery(guidanceFilterStoraged);
			}
		}
	}, []);

	const handleSubmit = (query: string) => {
		const newPageState = { ...pageState, query };

		if (query) {
			updateStateWithSearchPill(newPageState, query);
		}

		setPageState(newPageState);
		executeQuery(newPageState);
	};

	const executeQuery = (state: GuidanceFilterState) => {
		invokfilterChanged(state);
		dispatch(getGuidanceFilterState(state));
	};

	const invokfilterChanged = (state: GuidanceFilterState) => {
		// Filter items
		if (onFilterChanged) {
			let result: GuidancePuffModel[];

			if (state.pills.length === 0 && state.includeOnly) {
				result = guidanceItems.filter(
					(guide) => state.includeOnly === guide.isSupervisory
				);
			} else if (state.pills.length === 0) {
				result = [...guidanceItems];
			} else {
				result = guidanceItems.filter((guide) => {
					const userFilter = getSearchPill(state);

					for (let i = 0; i < state.pills.length; i++) {
						const option = state.pills[i];

						if (state.includeOnly && !guide.isSupervisory) {
							continue;
						}

						if (option.id.toString().startsWith(userSearchId)) {
							if (
								userFilter &&
								guide.heading
									.toUpperCase()
									.indexOf(userFilter.data.toUpperCase()) >= 0
							) {
								return true;
							}
						} else {
							let catId;
							// ID includes category index if e have multiple categories/dropdowns.
							const pos = option.id.toString().indexOf('-');
							catId =
								pos === -1
									? option.id.toString()
									: option.id.toString().substring(pos + 1);
							if (guide.areaIds.includes(parseInt(catId))) {
								return true;
							}
						}
					}
					return false;
				});
			}
			onFilterChanged(result);
		}
	};

	const getSearchPill = (state: GuidanceFilterState): PillItem | undefined => {
		return state.pills.find((item) => {
			return item.id === userSearchId;
		});
	};

	const updateStateWithSearchPill = (
		state: GuidanceFilterState,
		query: string
	) => {
		const queryPill = getSearchPill(state);

		if (queryPill) {
			const updatedQueryPill = {
				...queryPill,
				label: `${searchPrefixLabel}:${query}`,
				data: query,
			};
			state.pills = state.pills.map((item) => {
				if (item.id === userSearchId) {
					return updatedQueryPill;
				}
				return item;
			});
		} else {
			const newPill: PillItem = {
				id: userSearchId,
				label: `${searchPrefixLabel}:${query}`,
				data: query,
			};
			state.pills.push(newPill);
		}
	};

	const onCategorySelected = (index: number, id: string) => {
		const newPageState = cloneDeep(pageState);

		let foundCategory = categories[index].categoryOptions.find((item) => {
			return parseInt(id) === item.id;
		});

		if (foundCategory) {
			const newPill: PillItem = {
				id: `${index}-${foundCategory.id}`,
				label: foundCategory.text,
			};

			const foundPill = newPageState.pills.find((item) => {
				return newPill.id === item.id;
			});

			if (!foundPill) {
				newPageState.pills.push(newPill);
			}
		}
		setPageState(newPageState);
		executeQuery(newPageState);
	};

	const onIncludeOnlyChanged = (value: boolean) => {
		const newPageState = { ...pageState, includeOnly: value };
		setPageState(newPageState);
		executeQuery(newPageState);
	};

	const onPillRemove = (removeItem: PillItem) => {
		const toRemoveIndex = pageState.pills.findIndex((item) => {
			return removeItem.id === item.id;
		});

		if (toRemoveIndex > -1) {
			const newPills = pageState.pills
				.slice(0, toRemoveIndex)
				.concat(
					pageState.pills.slice(toRemoveIndex + 1, pageState.pills.length)
				);

			const newPageState: GuidanceFilterState = {
				...pageState,
				pills: newPills,
			};
			setPageState(newPageState);
			executeQuery(newPageState);
		}
	};

	return (
		<SearchInputForm
			label={searchInputLabel}
			query={pageState.query}
			onSubmit={handleSubmit}
		>
			<InlineControlls>
				<Pills items={pageState.pills} onRemove={onPillRemove}></Pills>
			</InlineControlls>

			<InlineControlls>
				<FieldsetInline>
					{categories.map((catItem, index) => {
						return (
							<CategoryDropdown
								id={`cat-${index}`}
								selectedId={pageState.categories[index.toString()]}
								key={index}
								category={catItem}
								onSelectChanged={(id) => onCategorySelected(index, id)}
							></CategoryDropdown>
						);
					})}
				</FieldsetInline>

				<FieldsetInline>
					<LabelContainer>
						<LabelWrapper>
							<legend>{includeLabel}</legend>
						</LabelWrapper>
						<CheckboxesInline>
							<Checkbox
								checked={pageState.includeOnly}
								onCheckedChanged={(value: boolean) => {
									onIncludeOnlyChanged(value);
								}}
							>
								{supervisoryGuidanceLabel}
							</Checkbox>
						</CheckboxesInline>
					</LabelContainer>
				</FieldsetInline>
			</InlineControlls>
		</SearchInputForm>
	);
};

export default GuidanceFilter;

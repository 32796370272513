/**
 * Helper utils
 */

import { dateFormat } from 'highcharts';
import { Category } from 'pages/microsite/MicrositeArticlePage/MicrositeArticlePage.model';
import { Rotate } from 'types/enums';
import { ModelLocalization } from 'types/epi';

//#region Date and Time

export const convertDateToLocalFormat = (dateIsoStr: string): string => {
	const date = new Date(dateIsoStr);
	return date.toLocaleDateString();
};

const enTxt = 'Last reviewed'; // Granskad
export const convertDateWithMonthWrittenOut = (
	dateIsoStr: string | null | undefined,
	lastReviewedText?: string | null | undefined
): string | null => {
	if (dateIsoStr) {
		let validDate = '';
		try {
			const date = new Date(dateIsoStr);
			if (lastReviewedText && lastReviewedText === enTxt) {
				const enDate = date.toLocaleDateString('en-GB', {
					day: 'numeric',
					month: 'long',
					year: 'numeric',
				});

				const monthIndex = enDate.indexOf(' ') + 1;
				const capitalizedMonth =
					enDate.substring(monthIndex, monthIndex + 1).toUpperCase() +
					enDate.substring(monthIndex + 1);

				const formattedDate =
					enDate.substring(0, monthIndex) +
					capitalizedMonth +
					enDate.substring(monthIndex + capitalizedMonth.length);

				validDate = formattedDate;
			} else {
				// default time
				const swDate = date.toLocaleString('sv-SE', {
					day: 'numeric',
					month: 'long',
					year: 'numeric',
				});
				validDate = swDate;
			}

			return validDate;
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	return null;
};

function isValidDate(d: any) {
	return d instanceof Date && !isNaN(d as any);
}

export const convertDateWithShortMonthWrittenOut = (
	dateIsoStr: string | null | undefined
): string | null => {
	if (dateIsoStr) {
		try {
			const date = new Date(dateIsoStr);
			if (!isValidDate(date)) return null;
			return date.toLocaleDateString('default', {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
			});
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	return null;
};

export const getTimeFromDate = (
	dateIsoStr: string | null | undefined
): string | null => {
	if (dateIsoStr) {
		try {
			return new Date(dateIsoStr).toLocaleTimeString('default', {
				timeStyle: 'short',
			});
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	return null;
};

export enum FormatType {
	short = 'short',
	long = 'long',
	short_prettified = 'short_prettified',
	long_prettified = 'long_prettified',
}

export const getMonthNames = (format?: FormatType): string[] => {
	return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (month) {
		return getMonthName(new Date(2000, month), format);
	});
};

export const getMonthName = (date: Date, format?: FormatType) => {
	// TODO: Use language settings in model

	const formatStr =
		format === FormatType.short_prettified || format === FormatType.short
			? 'short'
			: 'long';
	const name = date.toLocaleString('default', { month: formatStr });

	if (
		format === FormatType.short_prettified ||
		format === FormatType.long_prettified
	) {
		return capitalizeFirstLetter(name);
	}
	return name;
};

export const getWeekDayNames = (format?: FormatType): string[] => {
	return [0, 1, 2, 3, 4, 5, 6].map(function (day) {
		return getWeekdayName(new Date(2000, 1, day), format);
	});
};

export const getWeekdayName = (date: Date, format?: FormatType) => {
	// TODO: Use language settings in model
	const formatStr =
		format === FormatType.short_prettified || format === FormatType.short
			? 'short'
			: 'long';

	const name = date.toLocaleString('default', { weekday: formatStr });
	if (
		format === FormatType.short_prettified ||
		format === FormatType.long_prettified
	) {
		return capitalizeFirstLetter(name);
	}
	return name;
};

export const getShortTimeName = (date: Date) => {
	// TODO: Use language settings in model
	// Example: returns "11:26"
	return date.toLocaleString('default', { timeStyle: 'short' });
};

export const getShortWeekdayName = (date: Date) => {
	// TODO: Use language settings in model

	return date.toLocaleString('default', { weekday: 'long' });
};

export const getLongWeekdayName = (date: Date) => {
	// TODO: Use language settings in model

	return date.toLocaleString('default', { weekday: 'long' });
};

export const getShortMonthName = (date: Date) => {
	// TODO: Use language settings in model

	return date.toLocaleString('default', { month: 'short' });
};

export const getLongMonthName = (date: Date) => {
	// TODO: Use language settings in model
	return date.toLocaleString('default', { month: 'long' });
};

//#endregion

export const capitalizeFirstLetter = (text: string) => {
	return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
};

export const buildQueryString = (params: any) => {
	var queryString = Object.keys(params)
		.filter((key: string) => {
			const value = (params as any)[key];
			return value && value !== undefined && value !== null;
		})
		.map((key: string) => {
			return (
				encodeURIComponent(key) + '=' + encodeURIComponent((params as any)[key])
			);
		})
		.join('&');

	return queryString;
};

export const formatText = (format: string, total: number, filtered: number) => {
	if (format) {
		let temp = format;
		temp = temp.split('{0}').join(filtered.toString());
		temp = temp.split('{1}').join(total.toString());
		return temp;
	}
	return '';
};

export const translate = (
	id: string,
	fallback: string,
	localization: ModelLocalization | undefined
) => {
	if (localization && id in localization.translations) {
		return localization.translations[id];
	}

	return fallback;
};

export const getRotationDegrees = (trend: number) => {
	if (trend === -1) {
		return Rotate.Down;
	}

	if (trend === 1) {
		return Rotate.Up;
	}

	if (trend === 2) {
		return Rotate.Right;
	}

	if (trend === 3) {
		return Rotate.Left;
	}
};

export const formatCategories = (categories: Category[] | undefined) => {
	// Map the array to cat.name
	const categoryNames = categories?.map((cat) => cat.name);

	// Use join to concatenate with commas
	const result = categoryNames?.join(', ');

	return result;
};

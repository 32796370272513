/**
 * SiteHeader
 */

import { Grid } from 'components/Boilerplate/Grid';
import Link from 'components/Boilerplate/Link';
import Button from 'components/Button/Button';
import MenuDropdown from 'components/MenuDropdown';
import { SearchInputForm } from 'components/Panels/SearchInputForm';
import FocusTrap from 'focus-trap-react';
import { ButtonVariant } from 'pages/sharedModelTypes';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import {
	selectContent,
	selectLocalization,
	selectSearch,
} from 'store/modules/model';
import { breakpointsNumber } from 'theme/media-queries';
import {
	Lang,
	LogoModel,
	ModelQuickLinksItem,
	NavigationItem,
} from 'types/epi';
import { translate } from 'utils/helper-utils';
import {
	QuickLinksList,
	QuickLinksListItem,
	SearchContainer,
	SearchOverlay,
	SiteHeaderContainer,
	SiteHeaderContent,
	SiteHeaderLogoLink,
	SiteHeaderMenuAndQuickLinks,
	SiterHeaderMenuAndSearch,
} from './SiteHeader.styles';

interface Props {
	home?: NavigationItem;
	itemsAria?: string;
	quickLinks?: ModelQuickLinksItem[];
	lang?: Lang;
	isPopupMenuOpen: boolean;
	style?: any;
	onOpenMenu?: (isOpen: boolean) => void;
	onOpenSearchMenu?: (isOpen: boolean) => void;
	isSearchOpen: boolean;
	isMenuOpen: boolean;
	logo: LogoModel | undefined;
}

/** Main description for this component. */
const SiteHeader = React.forwardRef<any, Props>(
	(
		{
			home = { text: 'Home' } as NavigationItem,
			quickLinks = [],
			lang = {},
			itemsAria = '',
			isPopupMenuOpen,
			onOpenMenu,
			onOpenSearchMenu,
			isSearchOpen = false,
			isMenuOpen = false,
			logo,
		},
		menuButtonRef
	) => {
		const menuClick = () => {
			closeSearchMenu();
			if (onOpenMenu) {
				onOpenMenu(true);
			}
		};

		const [isSearchPanelOpen, setIsSearchPanelOpen] = useState<boolean>(
			isPopupMenuOpen
		);
		const pageContent = useSelector(selectContent);

		const [animate, setAnimate] = useState(false);

		const animSpeed = 200;
		const search = useSelector(selectSearch);

		const searchButtonRef = React.createRef<any>();
		const searchOverlayRef = React.createRef<any>();

		const location = useLocation();
		const history = useHistory();
		const localization = useSelector(selectLocalization);

		const ariaLabel = translate(
			'/frontend/components/searchBar/searcharialabel',
			'Search',
			localization
		);
		const searchButtonLabel = translate(
			'/frontend/components/headerMenu/search',
			'Search',
			localization
		);
		const menuButtonLabel = translate(
			'/frontend/components/headerMenu/menu',
			'Menu',
			localization
		);

		itemsAria = translate(
			'/frontend/components/headerMenu/itemsaria',
			'Main menu',
			localization
		);

		// hide QuickLinks till 635 pixel width
		const breakpointsQuickLinks: number = 635;

		const isNarrowDisplay = useMediaQuery({
			minWidth: breakpointsNumber.phone,
			maxWidth: breakpointsQuickLinks,
		});

		useEffect(() => {
			if (isSearchPanelOpen) {
				closeSearchMenu();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [location]);

		useEffect(() => {
			// This is to updated epi quicknavigator link with current page id
			var epiNavigator: HTMLAnchorElement | null = document.querySelector(
				'#epi-quickNavigator > li:first-of-type > a:first-of-type'
			);
			if (epiNavigator && pageContent && epiNavigator.href) {
				const prefix = ':///';
				const pos = epiNavigator.href.lastIndexOf(prefix);
				if (pos !== -1) {
					epiNavigator.href =
						epiNavigator.href.substring(0, pos) + prefix + pageContent.id;
				}
			}
		}, [location, pageContent]);

		useEffect(() => {
			if (onOpenSearchMenu) {
				onOpenSearchMenu(isSearchPanelOpen);
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isSearchPanelOpen, onOpenSearchMenu]);

		const closeSearchMenu = () => {
			setAnimate(false);
			setTimeout(() => {
				setIsSearchPanelOpen(false);
			}, animSpeed);
		};

		const openSearchMenu = () => {
			setIsSearchPanelOpen(true);
			setAnimate(true);
		};

		const setFocusOnSearchButton = () => {
			if (searchButtonRef && searchButtonRef.current) {
				searchButtonRef.current.focus();
			}
		};

		const handleSearchSubmit = (query: string) => {
			closeSearchMenu();

			history.push({ pathname: search?.searchPageUrl, search: `?q=${query}` });
			history.go(0);
		};

		const onSearchMenuEsc = () => {
			setFocusOnSearchButton();
			closeSearchMenu();
		};

		const onSearchMenuClick = () => {
			isSearchPanelOpen ? closeSearchMenu() : openSearchMenu();
		};

		const onMouseClickOutsideSearchOverlay = (e: any) => {
			if (searchOverlayRef.current === e.target) {
				e.preventDefault();
				closeSearchMenu();
			}
		};

		return (
			<FocusTrap
				active={!isMenuOpen && isSearchOpen}
				focusTrapOptions={{ preventScroll: true }}
			>
				<SiteHeaderContainer style={{ zIndex: 1 }}>
					<Grid paddingTop={false} paddingBottom={false}>
						<SiteHeaderContent>
							<SiteHeaderLogoLink
								showLinkIcon={false}
								data-testid="logoLink"
								to={home && home.url ? home.url : '/'}
							>
								<img data-testid="logoImage" src={logo?.src} alt={logo?.alt} />
							</SiteHeaderLogoLink>
							<SiterHeaderMenuAndSearch>
								<SiteHeaderMenuAndQuickLinks aria-label={itemsAria}>
									<Button
										iconName="menu"
										ref={menuButtonRef}
										iconPosition={isNarrowDisplay ? 0 : 1}
										aria-expanded={isPopupMenuOpen}
										aria-haspopup="dialog"
										onClick={menuClick}
										variant={ButtonVariant.Tertiary}
									>
										{menuButtonLabel}
									</Button>

									{!isNarrowDisplay && (
										<QuickLinks
											itemsAria={itemsAria}
											items={quickLinks}
										></QuickLinks>
									)}
								</SiteHeaderMenuAndQuickLinks>
								<>
									{(() => {
										if (lang && lang.items && lang.items?.length > 1) {
											const { items, menuHeaderIsItem } = lang;

											const activeItem = menuHeaderIsItem
												? items?.find((l) => !l.active)
												: items?.[0];
											return (
												<MenuDropdown
													links={items}
													mobileHeader={activeItem?.text}
												/>
											);
										}
									})()}
								</>
								<Button
									ref={searchButtonRef}
									aria-expanded={isSearchPanelOpen}
									iconPosition={isNarrowDisplay ? 0 : 1}
									iconName={isSearchPanelOpen ? 'cross' : 'search'}
									onClick={onSearchMenuClick}
									variant={ButtonVariant.Tertiary}
								>
									{searchButtonLabel}
								</Button>
							</SiterHeaderMenuAndSearch>
						</SiteHeaderContent>
					</Grid>

					<Transition
						in={animate}
						timeout={animSpeed}
						mountOnEnter={true}
						unmountOnExit={true}
					>
						{(state) => (
							<SearchOverlay
								role="dialog"
								aria-label={ariaLabel}
								aria-modal={true}
								ref={searchOverlayRef}
								state={state}
								onClick={onMouseClickOutsideSearchOverlay}
							>
								<SearchContainer state={state}>
									<Grid paddingTop={false} paddingBottom={false}>
										<SearchInputForm
											required
											onSubmit={handleSearchSubmit}
											onEsc={onSearchMenuEsc}
											query=""
										></SearchInputForm>
									</Grid>
								</SearchContainer>
							</SearchOverlay>
						)}
					</Transition>
				</SiteHeaderContainer>
			</FocusTrap>
		);
	}
);

export default SiteHeader;

/**
 *
 *
 */

interface QuickLinksProps {
	items: ModelQuickLinksItem[];
	itemsAria: string;
}

export type ActiveNode = {
	isActive: boolean;
};

/** Main description for this component. */
const QuickLinks = ({ items, itemsAria }: QuickLinksProps) => {
	const location = useLocation();

	function getPath(url: string): string | null {
		const urlpaths = url.split('/');
		if (url.charAt(0) === '/' && urlpaths.length > 0) {
			var paths = '/';
			urlpaths.forEach((path) => {
				if (path != '') paths += path + '/';
			});

			return paths;
		}
		return null;
	}

	return (
		<QuickLinksList>
			{items.map((item, index) => {
				let itemPath = getPath(item.url);
				return (
					<QuickLinksListItem
						isActive={location.pathname === itemPath}
						key={index}
					>
						<Link showLinkIcon={false} to={item.url}>
							{item.heading}
						</Link>
					</QuickLinksListItem>
				);
			})}
		</QuickLinksList>
	);
};

/**
 * QuizForm - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const ButtonContainer = styled.div`
	display: flex;
    max-width: 26rem;
    margin: 2rem auto 3rem;
    justify-content: center;


    ${mediaQueries.phone} {
        display: flex-column;
	}
`;

export const NextAndPrevButtons = styled.div`
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    margin: 2rem 0 1rem;
    justify-content: space-between;
`

export const QuizContainer = styled.div`
    border: 2px solid ${(props) => props.theme.colors.primary};
    padding: 1.5rem;
    max-width: 1000px;
    margin: 0 1rem;

    ${mediaQueries.desktop} {
        margin: 0 auto;
	}
`
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import { NVseGuidanceListingPageModel } from './NVseGuidanceListingPage.model';
import GuidanceFilter from './GuidanceFilter';
import EpiFragments from 'components/Boilerplate/EpiFragments';
import { GuidancePuffModel } from 'types/fragments';
import Tab from 'components/Tab';
import { TabPanel, TabProps } from 'components/Tab/Tab';
import { ViewType } from '../../types/enums';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { formatText } from 'utils/helper-utils';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { LinkTableList } from 'components/Panels/LinkTableList';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { SessionStorageModel } from 'types/epi';
import { useDispatch } from 'react-redux';
import { setSessionStorage } from 'store/modules/model';
import ListHeader from 'components/ListHeader';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';

type NVseGuidanceListingPageSessionModel = {
	tabPanel: {
		selectedId: string;
	};
};

/**
 * # Listningssida för vägledningar
 * Modeltype:<code>NVseGuidanceListingPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/1828651011/NVseGuidanceListingPage)
 *
 * Landningssida för webbplatsens vägledning
 */
const NVseGuidanceListingPage: React.FC<NVseGuidanceListingPageModel> = ({
	id,
	modelType,
	heading,
	preamble,
	exploreLabel,
	searchLabel,
	supervisoryGuidanceLabel,
	areasHeading,
	guidancePuffs,
	areas,
	categories,
	showMoreAreasLabel,
	searchInputLabel,
	displayedGuidancesText,
	bottomItems,
	epi,
	_properties = {},
	disableCustomHeadingLogic,
}) => {
	const { action } = useHistory();
	const themeContext = useContext(ThemeContext);

	const [selectedViewType, setSelectedViewType] = useState<ViewType>(
		ViewType.Card
	);

	const [selectedTabPage, setSelectedTabPage] = useState<string>('explore');

	const [guidanceItems, setGuidanceItems] = useState<GuidancePuffModel[]>(
		guidancePuffs
	);

	const [guidanceItemsCopy, setGuidanceItemsCopy] = useState<
		GuidancePuffModel[] | undefined
	>();
	const sessionStorageModel = useSessionStorage(modelType, id);
	const dispatch = useDispatch();

	const initialAreaItems = 20;

	useEffect(() => {
		if (sessionStorageModel && sessionStorageModel.data) {
			const sessionData = sessionStorageModel.data as NVseGuidanceListingPageSessionModel;
			setSelectedTabPage(sessionData.tabPanel.selectedId);
		}
	}, [sessionStorageModel]);

	useEffect(() => {
		if (action === 'POP') {
			if (typeof guidanceItemsCopy !== 'undefined') {
				setTimeout(() => {
					setGuidanceItems(guidanceItemsCopy);
				}, 100);
			}
		} else {
			setGuidanceItems(guidancePuffs);
		}
	}, [guidancePuffs]);

	const onNewGuidanceResult = (guides: GuidancePuffModel[]) => {
		setGuidanceItemsCopy(guides);
		setGuidanceItems(guides);
	};

	const onTabPageSelected = (panelId: string) => {
		setSelectedTabPage(panelId);

		const data = {
			tabPanel: {
				selectedId: panelId,
			},
		} as NVseGuidanceListingPageSessionModel;

		const payload = {
			modelType,
			id,
			data,
		} as SessionStorageModel;

		dispatch(setSessionStorage(payload));
	};

	// TODO: Hardcoded text
	const showMoreLabel = 'Visa fler';

	const tabPops = {
		label: 'Utforska',
		defaultTabId: selectedTabPage,
		autoSelect: false,
		items: [
			{
				heading: exploreLabel,
				id: 'explore',
				iconName: null,
			},
			{
				heading: searchLabel,
				id: 'search',
				iconName: 'search',
			},
		],
	} as TabProps;

	const formattedHeader =
		displayedGuidancesText != null
			? formatText(
					displayedGuidancesText,
					guidancePuffs.length,
					guidanceItems.length
			  )
			: null;

	// figure out the last block type, so we know the bottom padding (space between last element and footer)
	let lastBlockType = BlockType.Element;

	if (selectedTabPage === 'explore') {
		if (areas.length > 0) {
			lastBlockType = BlockType.Element;
		}
		if (bottomItems.length > 0) {
			lastBlockType = getLastItemBlockType(
				bottomItems,
				themeContext,
				disableCustomHeadingLogic
			);
		}
	} else {
		if (guidanceItems.length > 0) {
			lastBlockType = BlockType.Element;
		}
	}

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	const onListViewChanged = (view: ViewType) => {
		if (view === ViewType.List) {
			setSelectedViewType(ViewType.List);
		} else {
			setSelectedViewType(view);
		}
	};

	return (
		<Space
			top={themeContext.spacing.getPageTopPadding()}
			bottom={spaceAfterLast}
		>
			<Grid paddingTop={false} paddingBottom={false}>
				<GrantsHeader
					columns={8}
					headingLevel={1}
					heading={heading}
					preamble={preamble}
					heading_htmlAttributes={_properties?.heading}
					preamble_htmlAttributes={_properties?.preamble}
				></GrantsHeader>
			</Grid>

			<Space top={themeContext.spacing.getElement()}>
				<Grid paddingTop={false} paddingBottom={false}>
					<Tab
						onSelect={onTabPageSelected}
						{...tabPops}
						defaultTabId={selectedTabPage}
					></Tab>
				</Grid>
			</Space>

			<div>
				<TabPanel panelId="explore" selected={selectedTabPage === 'explore'}>
					{areas.length > 0 && (
						<LinkTableList
							headingLevel={2}
							heading={areasHeading}
							items={areas}
							showMoreLabel={showMoreAreasLabel}
							initialMaxCount={initialAreaItems}
						></LinkTableList>
					)}
					<EpiFragments
						previousBlockType={
							areas.length > 0 ? BlockType.SectionNew : undefined
						}
						epi={epi}
						headingLevel={2}
						fragments={bottomItems}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				</TabPanel>

				<TabPanel panelId="search" selected={selectedTabPage === 'search'}>
					<Grid paddingTop={false} paddingBottom={false}>
						<GuidanceFilter
							categories={categories}
							guidanceItems={guidancePuffs}
							searchInputLabel={searchInputLabel}
							supervisoryGuidanceLabel={supervisoryGuidanceLabel}
							onFilterChanged={onNewGuidanceResult}
						/>

						<Space top={themeContext.spacing.getElement()}>
							<ListHeader
								headingLevel={2}
								heading={formattedHeader}
								showOptions={guidanceItems.length > 0}
								onViewChange={onListViewChanged}
								defaultValue={selectedViewType}
							/>
						</Space>

						{guidanceItems.length > 0 && (
							<Space top={themeContext.spacing.space3}>
								<FragmentPuffList
									initialMaxCount={6}
									options={{
										insideCell: false,
										insideInnerGrid: false,
										insideGrid: true,
										headingLevel: 2, // TODO:
										view: selectedViewType,
										themeContext: themeContext,
									}}
									showMoreLabel={showMoreLabel}
									items={guidanceItems}
									disableCustomHeadingLogic={disableCustomHeadingLogic}
								></FragmentPuffList>
							</Space>
						)}
					</Grid>
				</TabPanel>
			</div>
		</Space>
	);
};

export default NVseGuidanceListingPage;

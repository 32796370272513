import React from 'react';
import { useSelector } from 'react-redux';
import {
	selectBreadcrumbs,
	selectContent,
	selectCookieBar,
	selectFooter,
	selectNavigation,
	selectQuickLinks,
	selectTracer,
	selectLang,
	selectGlobalMessages,
	selectLogo,
	getSkipToContent,
	selectAppLayout,
} from 'store/modules/model';
import { ContentTypes } from 'types/pages';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import {
	Lang,
	ModelBreadcrumb,
	ModelCookieBar,
	ModelFooter,
	ModelGlobalMessages,
	ModelNavigation,
	ModelQuickLinksItem,
	ModelTracer,
	LogoModel,
} from 'types/epi';
import MicrositeApp from 'MicrositeApp';
import NVseApp from 'NVseApp';
import { LoaderStatic } from 'components/Boilerplate/Loader';
import { useEpiContextAndSavedEvent } from 'hooks/useEpiContextAndSavedEvent';
import Meta from 'components/Boilerplate/Meta/Meta';

interface PropsInterface {
	Router: any;
}

export interface AppProps {
	Router: any;
	isNarrowDisplay: boolean;
	tracer: ModelTracer | undefined;
	cookieBar: ModelCookieBar | undefined;
	globalMessages: ModelGlobalMessages | undefined;
	breadcrumbs: ModelBreadcrumb[] | undefined;
	navigation: ModelNavigation | undefined;
	quickLinks: ModelQuickLinksItem[] | undefined;
	skipToContent?: string;
	lang: Lang | undefined;
	footer: ModelFooter | undefined;
	pageContent: ContentTypes | undefined;
	logo?: LogoModel | undefined;
}

export const routePatterns = ['/:siteRoute*'];

export const getLocation = (pageContent: ContentTypes | undefined) => {
	let location = '';

	if (pageContent?.url) {
		location = pageContent.url;
	} else if (typeof window !== 'undefined') {
		location = window.location.pathname;
	}

	return location;
};

const App: React.FC<PropsInterface> = ({ Router }) => {
	const pageContent = useSelector(selectContent);
	const tracer = useSelector(selectTracer);
	const globalMessages = useSelector(selectGlobalMessages);
	const cookieBar = useSelector(selectCookieBar);
	const navigation = useSelector(selectNavigation);
	const quickLinks = useSelector(selectQuickLinks);
	const skipToContent = useSelector(getSkipToContent);
	const breadcrumbs = useSelector(selectBreadcrumbs);
	const lang = useSelector(selectLang);
	const footer = useSelector(selectFooter);
	const logo = useSelector(selectLogo);
	const appTypeLayout = useSelector(selectAppLayout);

	const isNarrowDisplay = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.desktop - 1,
	});

	useEpiContextAndSavedEvent();

	return (
		<>
			<Meta />

			{appTypeLayout === undefined && <LoaderStatic />}
			{appTypeLayout?.name === 'nvv' ? (
				<NVseApp
					isNarrowDisplay={isNarrowDisplay}
					Router={Router}
					tracer={tracer}
					cookieBar={cookieBar}
					globalMessages={globalMessages}
					breadcrumbs={breadcrumbs}
					navigation={navigation}
					quickLinks={quickLinks}
					skipToContent={skipToContent}
					lang={lang}
					footer={footer}
					pageContent={pageContent}
					logo={logo}
				/>
			) : (
				<MicrositeApp
					isNarrowDisplay={isNarrowDisplay}
					Router={Router}
					tracer={tracer}
					cookieBar={cookieBar}
					globalMessages={globalMessages}
					breadcrumbs={breadcrumbs}
					navigation={navigation}
					quickLinks={quickLinks}
					skipToContent={skipToContent}
					lang={lang}
					footer={footer}
					pageContent={pageContent}
				/>
			)}
		</>
	);
};

export default App;

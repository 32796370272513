import EpiFragments, {
	BlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import Pagination from 'components/Pagination';
import { CategoryList } from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage';
import {
	CheckboxesInline,
	FieldsetInline,
	InlineControlls,
	RadioButtonContainer,
} from 'pages/NVsePublicationsListingPage/NVsePublicationsListingPage.styles';
import { LinkType, NumberRage } from 'pages/sharedModelTypes';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchSearchResult,
	selectLocalization,
	selectSessionStorage,
	setSessionStorage,
} from 'store/modules/model';
import { selectReact } from 'store/modules/react';
import { ViewType } from 'types/enums';
import { FragmentModelTypes, RegulationPuffModel } from 'types/fragments';
import { buildQueryString, formatText, translate } from 'utils/helper-utils';
import { NVseRegulationsListingPageModel } from './NVseRegulationsListingPage.model';
import Checkbox from 'components/Checkbox';
import Radio from 'components/Radio';
import { LabelContainer, LabelWrapper } from 'components/Panels/Panels.styles';
import { CategoryDropdown } from './CategoryDropdown';
import { DateRangeDropdown } from './DateRangeDropdown';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { SearchInputForm } from 'components/Panels/SearchInputForm';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { SearchResultModel } from 'types/epi';
import {
	createPageSessionStorage,
	getSessionStorage,
	usePageSessionStorage,
} from 'hooks/useSessionStorage';
import ListHeader from 'components/ListHeader';
import { ThemeContext } from 'styled-components';

type RegulationSearchResultItemModel = {
	heading: string | null;
	decal: string | null;
	link: LinkType | null;
	validFromDate: string | null;
	tag: string | null;
	regulationId: string | null;
};

type SearchRegulationsParams = {
	query?: string;
	page?: number;
	size?: number;
	catId?: string;
	status?: number;
	adviceOnly?: boolean;
	startyear?: number;
	endyear?: number;
};

type RegulationsListingPageSessionModel = {
	numberRange: NumberRage;
	selectedViewType: ViewType;
	categories: CategoryList;
	status: number;
	adviceOnly: boolean;
	page: number | null;
	query: string;
	searchResponse: SearchResultModel | null;
	sortBy: number;
};

/**
 * # Författningssamling
 * Modeltype:<code>NVseRegulationsListingPage</code>
 *
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2499903526/NVseRegulationsListingPage)
 *
 * Landningssida för webbplatsens föreskrifter
 */
const NVseRegulationsListingPage: React.FC<NVseRegulationsListingPageModel> = ({
	modelType,
	id,
	heading,
	preamble,
	searchLabel,
	sortByNumber,
	sortByRelevant,
	categories,
	status,
	displayedSearchHitsText,
	generalAdviceLabel,
	selectableYears,
	apiUrl,
	_properties = {},
	disableCustomHeadingLogic,
	bottomItems,
}) => {
	const themeContext = useContext(ThemeContext);

	// TODO: Hardcoded texts
	const showMoreLabel = 'Visa alla';

	const initialSessionDate: RegulationsListingPageSessionModel = {
		numberRange: {
			from: selectableYears.startYear,
			to: selectableYears.endYear,
		},
		selectedViewType: ViewType.List,
		categories: {},
		adviceOnly: false,
		status: 0,
		page: null,
		query: '',
		searchResponse: null,
		sortBy: 0,
	};

	const dispatch = useDispatch();
	const { apiUrl: baseApiUrl } = useSelector(selectReact);

	const [fixedResults, setFixedResults] = useState<FragmentModelTypes[]>([]);
	const [selectedOpt, setSelectedOpt] = useState<string | number | undefined>(
		0
	);
	const sessionStorageModel = useSelector(selectSessionStorage);
	const pageSessionStorage = usePageSessionStorage<RegulationsListingPageSessionModel>(
		modelType,
		id,
		initialSessionDate
	);

	const localization = useSelector(selectLocalization);

	const includeLabel = translate(
		'/frontend/pages/includeonly',
		'Include only',
		localization
	);

	const sortByLabel = translate(
		'/frontend/pages/sortByLabel',
		'Sort by',
		localization
	);

	useEffect(() => {
		// If we do not have any saved state, we execute the search.
		const pageSessionStorage = getSessionStorage(
			sessionStorageModel,
			modelType,
			id
		);
		if (!pageSessionStorage) {
			executeQuery(initialSessionDate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionStorageModel]);

	useEffect(() => {
		const getFixedSearchResult = (
			searchResponse: SearchResultModel
		): RegulationPuffModel[] => {
			if (searchResponse) {
				return searchResponse.results.map(
					(item: RegulationSearchResultItemModel) => {
						return {
							modelType: 'RegulationPuffModel',
							decal: item.decal,
							heading: item.heading,
							regulationId: item.regulationId,
							validFromDate: item.validFromDate,
							tag: item.tag,
							link: item.link,
						} as RegulationPuffModel; // TODO
					}
				);
			} else {
				return [];
			}
		};

		if (pageSessionStorage.searchResponse) {
			setFixedResults(getFixedSearchResult(pageSessionStorage.searchResponse));
		}
	}, [pageSessionStorage]);

	const handleSubmit = (query: string) => {
		const data = {
			...pageSessionStorage,
			page: 1,
			query,
		} as RegulationsListingPageSessionModel;

		//Sort by relevance if search terms are specified
		data.sortBy = query ? 1 : 0;
		setSelectedOpt(data.sortBy);

		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const executeQuery = (state: RegulationsListingPageSessionModel) => {
		const urlQuery = buildQueryFromModel(state);

		// TODO: Switch to real API
		// dispatch(fetchSearchResult(modelType, id, "searchResponse", "https://8167b974-87ff-4a2b-93a0-e92912c37eef.mock.pstmn.io", ApiPath.NVseRegulationsDemo, urlQuery));

		dispatch(
			fetchSearchResult(
				modelType,
				id,
				'searchResponse',
				baseApiUrl,
				apiUrl,
				urlQuery
			)
		);

		// move to the top after got results
		window.scrollTo(0, 0);
	};

	const buildQueryFromModel = (
		pageState: RegulationsListingPageSessionModel
	): string => {
		const queryParams = {
			page: pageState.page,
			adviceOnly: pageState.adviceOnly,
			status: pageState.status,
			startyear: pageState.numberRange.from,
			endyear: pageState.numberRange.to,
			sortBy: pageState.sortBy,
		} as SearchRegulationsParams;

		if (pageState.query) {
			queryParams.query = pageState.query;
		}

		// Special handling for categories, the API can only handle one category at a time
		if (Object.keys(pageState.categories).length) {
			// Pick first category
			queryParams.catId =
				pageState.categories[Object.keys(pageState.categories)[0]];
		}

		return buildQueryString(queryParams);
	};

	// const onListViewChanged = (view: ViewType) => {
	// 	const data = {
	// 		...pageSessionStorage,
	// 		selectedViewType: view,
	// 	} as RegulationsListingPageSessionModel;
	// 	const payload = createPageSessionStorage(modelType, id, data);
	// 	dispatch(setSessionStorage(payload));
	// };

	const onCategorySelected = (index: Number, catId: string) => {
		const newCategories = { ...pageSessionStorage.categories };
		if (catId) {
			newCategories[index.toString()] = catId;
		} else if (newCategories[index.toString()]) {
			delete newCategories[index.toString()];
		}

		const data = {
			...pageSessionStorage,
			page: 1,
			categories: newCategories,
		} as RegulationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const onYearRangeChanged = (range: NumberRage) => {
		const data = {
			...pageSessionStorage,
			page: 1,
			numberRange: range,
		} as RegulationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const onStatusChanged = (status: string) => {
		const data = {
			...pageSessionStorage,
			status: parseInt(status),
			page: 1,
		} as RegulationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const onIncludeAdviceChanged = (value: boolean) => {
		const data = {
			...pageSessionStorage,
			adviceOnly: value,
			page: 1,
		} as RegulationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	//#region Pagination
	const changePage = (page: number) => {
		const data = {
			...pageSessionStorage,
			page: page,
		} as RegulationsListingPageSessionModel;
		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};

	const onPaginationPreviousClick = () => {
		if (!pageSessionStorage.searchResponse?.results) return;

		let newPageNr = pageSessionStorage.searchResponse.page
			? pageSessionStorage.searchResponse.page
			: 1;
		if (newPageNr > 0) {
			changePage(newPageNr - 1);
		}
	};

	const onPaginationNextClick = () => {
		if (!pageSessionStorage.searchResponse?.results) return;

		let newPageNr = pageSessionStorage.searchResponse.page
			? pageSessionStorage.searchResponse.page
			: 1;
		if (newPageNr < pageSessionStorage.searchResponse.totalPages) {
			changePage(newPageNr + 1);
		}
	};
	//#endregion

	// #region Space calculation

	let lastBlockType = BlockType.Element;
	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);
	//#endregion

	// #radio button
	const toogleRadioBtn = (optSelected: string | number | undefined) => {
		setSelectedOpt(optSelected);
		onSortedByChanged(optSelected);
	};

	const onSortedByChanged = (optSelected: string | number | undefined) => {
		const data = {
			...pageSessionStorage,
			sortBy: optSelected,
			page: 1,
		} as RegulationsListingPageSessionModel;
		console.log(data);

		const payload = createPageSessionStorage(modelType, id, data);
		dispatch(setSessionStorage(payload));

		executeQuery(payload.data);
	};
	// #end radio button
	return (
		<>
			<Space
				top={themeContext.spacing.getPageTopPadding()}
				bottom={spaceAfterLast}
			>
				<Grid paddingTop={false} paddingBottom={false}>
					<GrantsHeader
						headingLevel={1}
						heading={heading}
						preamble={preamble}
						heading_htmlAttributes={_properties?.heading}
						preamble_htmlAttributes={_properties?.preamble}
					></GrantsHeader>

					<Space top={themeContext.spacing.space4}>
						<SearchInputForm
							label={searchLabel}
							query={
								pageSessionStorage.searchResponse
									? pageSessionStorage.searchResponse.query
									: ''
							}
							onSubmit={handleSubmit}
						>
							<InlineControlls>
								<FieldsetInline>
									{categories.map((catItem, index) => {
										return (
											<CategoryDropdown
												id={`cat-${index}`}
												selectedId={
													pageSessionStorage.categories[index.toString()]
												}
												key={index}
												category={catItem}
												onSelectChanged={(id) => onCategorySelected(index, id)}
											></CategoryDropdown>
										);
									})}
								</FieldsetInline>

								<FieldsetInline>
									<CategoryDropdown
										id="status"
										allowEmpty={false}
										selectedId={pageSessionStorage.status.toString()}
										category={status}
										onSelectChanged={onStatusChanged}
									></CategoryDropdown>
								</FieldsetInline>

								<FieldsetInline>
									<DateRangeDropdown
										fromLabel={selectableYears.startYearName}
										toLabel={selectableYears.endYearName}
										onYearRangeChanged={onYearRangeChanged}
										label={selectableYears.name}
										selectedRange={pageSessionStorage.numberRange}
										numberRange={{
											from: selectableYears.startYear,
											to: selectableYears.endYear,
										}}
									></DateRangeDropdown>
								</FieldsetInline>

								<FieldsetInline>
									<LabelContainer>
										<LabelWrapper>
											<legend aria-label={includeLabel}>{includeLabel}</legend>
										</LabelWrapper>
										<CheckboxesInline>
											<Checkbox
												checked={pageSessionStorage.adviceOnly}
												onCheckedChanged={(value: boolean) => {
													onIncludeAdviceChanged(value);
												}}
											>
												{generalAdviceLabel}
											</Checkbox>
										</CheckboxesInline>
									</LabelContainer>
								</FieldsetInline>
								<FieldsetInline>
									<LabelContainer>
										<LabelWrapper>
											<legend aria-label={sortByLabel}>{sortByLabel}</legend>
										</LabelWrapper>
										<RadioButtonContainer>
											<Radio
												id={'0'}
												label={sortByNumber}
												options={[{ id: 0, value: sortByNumber }]}
												selectedId={selectedOpt}
												onSelectedChanged={(id) => toogleRadioBtn(id)}
											/>
											<Radio
												id={'1'}
												label={sortByRelevant}
												options={[{ id: 1, value: sortByRelevant }]}
												selectedId={selectedOpt}
												onSelectedChanged={(id) => toogleRadioBtn(id)}
											/>
										</RadioButtonContainer>
									</LabelContainer>
								</FieldsetInline>
							</InlineControlls>
						</SearchInputForm>
					</Space>

					{pageSessionStorage && pageSessionStorage.searchResponse && (
						<Space top={themeContext.spacing.getElement()}>
							<ListHeader
								headingLevel={2}
								heading={formatText(
									displayedSearchHitsText,
									fixedResults.length,
									pageSessionStorage.searchResponse.numberOfHits
								)}
								showOptions={fixedResults.length > 0}
								defaultValue={pageSessionStorage.selectedViewType}
							/>
							{fixedResults.length > 0 && (
								<Space top={themeContext.spacing.space3}>
									<FragmentPuffList
										options={{
											insideCell: false,
											insideInnerGrid: false,
											insideGrid: true,
											headingLevel: 2, // TODO:
											view: pageSessionStorage.selectedViewType,
											themeContext: themeContext,
										}}
										showMoreLabel={showMoreLabel}
										items={fixedResults}
										disableCustomHeadingLogic={disableCustomHeadingLogic}
									></FragmentPuffList>
								</Space>
							)}

							{pageSessionStorage.searchResponse.totalPages > 1 && (
								<Space top={themeContext.spacing.space3}>
									<Pagination
										onPreviousSlide={onPaginationPreviousClick}
										inactivateBackwardButton={
											pageSessionStorage.searchResponse.page <= 1
										}
										onNextSlide={onPaginationNextClick}
										inactivateForwardButton={
											pageSessionStorage.searchResponse.page >=
											pageSessionStorage.searchResponse.totalPages
										}
									/>
								</Space>
							)}
						</Space>
					)}
				</Grid>
			</Space>
			{bottomItems && (
				<EpiFragments
					fragments={bottomItems as FragmentModelTypes[]}
					disableCustomHeadingLogic={disableCustomHeadingLogic}
				/>
			)}
		</>
	);
};

export default NVseRegulationsListingPage;

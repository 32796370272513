import Button from "../../Button";
import React, { FormEvent, useRef, useState } from "react";
import { ButtonContainer, NextAndPrevButtons, QuizContainer } from "./QuizForm.styles";
import Quiz, { QuizModel } from "./Quiz";
import AnswerResult from "./QuizResult";
import { FragmentListModel } from "types/fragments";
import { useQuizForm } from "hooks/useQuizForm";


export interface QuizFormModel {
    questions: QuizModel[];
    prevButtonText: string;
    nextButtonText: string;
    resultButtonText: string;
    resultTitle: string;
    yourAnswerText: string;
    correctAnswerText: string;
    minimumPassing: number;
    failedText: FragmentListModel | null;
    passedText: FragmentListModel | null;
}

const QuizForm:React.FC<QuizFormModel> = ({
    questions,
    nextButtonText,
    prevButtonText,
    resultButtonText,
    resultTitle,
    yourAnswerText,
    correctAnswerText,
    minimumPassing,
    failedText,
    passedText,
}) => {
    
    const { quizFormState, selectedAnswer, setAnswer, setSelectedQuiz } = useQuizForm();
    const [seeResult, setSeeResult] = useState(false);
    const container = useRef<HTMLDivElement>(null);

    const nextQuestion = (e: FormEvent) => {
        e.preventDefault();
        if(quizFormState.selectedQuiz < questions.length - 1){
            if(container.current) container.current.focus();
            setSelectedQuiz(quizFormState.selectedQuiz + 1);
        }
    }

    const prevQuestion = (e: FormEvent) => {
        e.preventDefault();
        if(quizFormState.selectedQuiz > 0){
            if(container.current) container.current.focus();
            setSelectedQuiz(quizFormState.selectedQuiz - 1);
        }
    }

    const viewResult = (e: FormEvent) => {
        e.preventDefault();
        if(quizFormState.answers.length === questions.length){
            setSeeResult(true);
            sessionStorage.removeItem("quizForm");
        }
    }

    return (
        <form>
            <QuizContainer ref={container}>
                {(quizFormState.answers.length === questions.length && seeResult) ?
                    <AnswerResult 
                    resultTitle={resultTitle} 
                    yourAnswerText={yourAnswerText} 
                    correctAnswerText={correctAnswerText}
                    minimumPassing={minimumPassing}
                    failedText={failedText}
                    passedText={passedText}
                    answers={quizFormState.answers}
                    /> :
                    <>
                        <Quiz 
                        {...questions[quizFormState.selectedQuiz]}
                        setAnswer={setAnswer}
                        selectedQuiz={quizFormState.selectedQuiz}
                        selectedAnswer={selectedAnswer}
                        />
                        <NextAndPrevButtons>
                            <Button
                            aria-label={prevButtonText} 
                            disabled={quizFormState.selectedQuiz == 0} 
                            onClick={prevQuestion}>{prevButtonText}</Button>
                            <Button 
                            aria-label={nextButtonText} 
                            disabled={quizFormState.selectedQuiz == questions.length - 1} 
                            onClick={nextQuestion}>{nextButtonText}</Button>
                        </NextAndPrevButtons>
                    </>
                }
            </QuizContainer>
            {!seeResult &&
                <ButtonContainer>
                    <Button 
                    aria-label={resultButtonText} 
                    onClick={viewResult} 
                    disabled={quizFormState.answers.length != questions.length}>{resultButtonText}</Button>
                </ButtonContainer>
            }
        </form>
    )
}

export default QuizForm
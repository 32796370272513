/**
 * Panels - styles
 */

import { ThemeProps } from 'components/Banner/Banner';
import { getThemeBorderColor } from 'pages/sharedModelTypes';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { listViewMode } from './Panels';

export const Div = styled.div``;

export type Placement = {
	textToRight: boolean;
};

export const ColumnContainerDevider = styled.div<ThemeProps>`
	opacity: 0.25;
	width: 1px;
	background-color: ${(p) => getThemeBorderColor(p.theme, p.themeName)};
	justify-self: center;
`;

export const ColumnContainerWithDevider = styled.div<Placement>`
	display: grid;

	grid-template-columns: 1fr ${(props) => props.theme.spacing.space5 * 2}rem 1fr;

	> :last-child {
		${(p) => p.textToRight && 'grid-column: 1;grid-row: 1;'};
	}
	> :first-child {
		${(p) => p.textToRight && 'grid-column: 3;grid-row: 1;'};
	}

	${mediaQueries.phoneOrTablet} {
		grid-template-columns: 100%;
		> :first-child {
			grid-row: 1;
			grid-column: 1;
		}
		> :last-child {
			grid-row: 3;
			grid-column: 1;
		}

		${ColumnContainerDevider as any} {
			margin: ${(props) => props.theme.spacing.space2 * 2}rem 0rem;
			height: 1px;
			width: 100%;
		}
	}
`;

export const LabelContainer = styled.div<any>`
	display: inline-flex;
	flex-direction: ${(props) => (props.flexRow ? 'row' : 'column')};
	align-items: ${(props) => (props.flexRow ? 'baseline' : null)};
	> :first-child {
		display: block;
		margin-right: ${(props) => (props.flexRow ? '0.5rem' : '0px')};
	}
	margin-right: ${(props) => props.theme.spacing.space3}rem;
`;

export const FieldsetContainer = styled.fieldset<any>`
	display: inline-flex;
	align-items: ${(props) => (props.flexRow ? 'baseline' : null)};
	flex-direction: ${(props) => (props.flexRow ? 'row' : 'column')} !important;
	> :first-child {
		display: block;
		margin-right: ${(props) => (props.flexRow ? '0.5rem' : '0px')};
	}
	flex-wrap: wrap;
	margin-right: ${(props) => props.theme.spacing.space3}rem !important;
`;

export const LabelWrapper = styled.div`
	legend,
	label {
		font-family: 'Akkurat Pro';
		font-weight: bold;
		margin-bottom: 0.25rem;
	}
`;

const getListStyle = () => {
	return css`
		flex-direction: column;
		flex-wrap: nowrap;
	`;
};

export const ListItemsStyle = styled.div<listViewMode>`
	display: flex;
	flex-wrap: wrap;
	${(p) => p.cardView === false && getListStyle()};

	& > * {
		margin-bottom: ${(props) => props.theme.spacing.space2}rem;
	}

	${mediaQueries.phone} {
		> :last-child {
			margin-bottom: ${(props) =>
				props.theme.isMicrositeActive ? '1' : '0'}rem;
		}
	}

	${mediaQueries.tabletOrLarger} {
		margin-bottom: ${(props) =>
			props.theme.isMicrositeActive ? '0' : '-1'}rem;

		margin-right: -${(props) => props.theme.spacing.space2}rem;
		& > * {
			margin-bottom: ${(props) => props.theme.spacing.space2}rem;
			margin-right: ${(props) => props.theme.spacing.space2}rem;
		}
	}
`;

export const CommonDecalStyle = styled.div`
	${(props) => props.theme.typography.style9.getStyle()};
	color: ${(props) => props.theme.palette.text.primary};
	letter-spacing: 1.07px;
	text-transform: uppercase;

	${mediaQueries.phone} {
		font-size: ${px2rem(14)};
	}
`;


import EpiFragments from 'components/Boilerplate/EpiFragments';
import {
	BlockType,
	getLastItemBlockType,
	getSpaceToAddBefore,
} from 'components/Boilerplate/EpiFragments/EpiFragments';
import { Grid } from 'components/Boilerplate/Grid';
import Space from 'components/Boilerplate/Space';
import { FragmentPuffList } from 'components/Panels/FragmentPuffList';
import { GrantsHeader } from 'components/Panels/GrantsHeader';
import { IndexPanel } from 'components/Panels/IndexPanel';
import Tab, { TabPanel, TabProps } from 'components/Tab/Tab';
import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { ViewType } from 'types/enums';
import { NVseSubjectAreaListingPageModel } from './NVseSubjectAreaListingPage.model';

/**
 * # Listningssida för ämnen 
 * Modeltype:<code>NVseSubjectAreaListingPage</code>
 * 
 * [API contract](https://consid.atlassian.net/wiki/spaces/NNN/pages/2233892865/NVseSubjectAreaListingPage)
 * 
 * Landningssida för webbplatsens ämnesområden
 */
const NVseSubjectAreaListingPage: React.FC<NVseSubjectAreaListingPageModel> = ({
	heading,
	preamble,
	bottomItems,
	epi,
	exploreLabel,
	searchLabel,
	promotedItems,
	subjectAreaLinks,
	topItems,
	_properties = {},
	disableCustomHeadingLogic
}) => {
	const themeContext = useContext(ThemeContext);

	const [selectedTabPanel, setSelectedTabPanel] = useState<string>('explore');
	const numberOfColumnsForLinks = 2;

	// TODO: hardcoded text
	const showMoreLabel = 'Visa fler';

	const onSelectedTab = (panelId: string) => {
		setSelectedTabPanel(panelId);
	};

	const tabPanelProps = {
		label: 'Sidor',
		defaultTabId: selectedTabPanel,
		autoSelect: false,
		items: [
			{
				heading: exploreLabel,
				id: 'explore',
				iconName: null,
			},
			{
				heading: searchLabel,
				id: 'search',
				iconName: null,
			},
		],
	} as TabProps;

	let lastBlockType = BlockType.Element;
	let blockTypeBefore_tab: BlockType = lastBlockType;
	let blockTypeBefore_bottomItems: BlockType = lastBlockType;

	if (topItems.length > 0) {
		lastBlockType = getLastItemBlockType(topItems, themeContext, disableCustomHeadingLogic);
		blockTypeBefore_tab = lastBlockType;
		blockTypeBefore_bottomItems = lastBlockType;
	}

	if (selectedTabPanel === 'explore') {
		if (promotedItems.length > 0) {
			lastBlockType = BlockType.Element;
			blockTypeBefore_bottomItems = lastBlockType;
		}
	}

	if (selectedTabPanel === 'search') {
		if (subjectAreaLinks.length > 0) {
			lastBlockType = BlockType.Element;
			blockTypeBefore_bottomItems = lastBlockType;
		}
	}

	if (bottomItems.length > 0) {
		lastBlockType = getLastItemBlockType(bottomItems, themeContext, disableCustomHeadingLogic);
	}

	const spaceBeforeTab = getSpaceToAddBefore(
		blockTypeBefore_tab,
		BlockType.SectionNew,
		themeContext
	);

	const spaceAfterLast = getSpaceToAddBefore(
		lastBlockType,
		BlockType.SectionBanner,
		themeContext
	);

	return (
		<>
			<Space top={themeContext.spacing.getPageTopPadding()} bottom={spaceAfterLast}>
				<Grid paddingTop={false} paddingBottom={false}>
					<GrantsHeader
						heading={heading}
						headingLevel={1}
						preamble={preamble}
						heading_htmlAttributes={_properties?.heading}
						preamble_htmlAttributes={_properties?.preamble}
					></GrantsHeader>
				</Grid>

				{topItems.length > 0 && (
					<EpiFragments
						epi={epi}
						headingLevel={2}
						fragments={topItems}
						previousBlockType={BlockType.Element}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				)}

				<Space top={spaceBeforeTab}>
					<Grid paddingTop={false} paddingBottom={false}>
						<Tab onSelect={onSelectedTab} {...tabPanelProps}></Tab>
					</Grid>
				</Space>
				<div>
					<TabPanel panelId="explore" selected={selectedTabPanel === 'explore'}>
						{promotedItems.length > 0 && (
							<Grid paddingTop={false} paddingBottom={false}>
								<FragmentPuffList
									options={{
										insideCell: false,
										insideInnerGrid: false,
										insideGrid: true,
										headingLevel: 2, // TODO:
										view: ViewType.Card,
										themeContext: themeContext
									}}
									showMoreLabel={showMoreLabel}
									items={promotedItems}
									disableCustomHeadingLogic={disableCustomHeadingLogic}
								></FragmentPuffList>
							</Grid>
						)}
					</TabPanel>
					<TabPanel panelId="search" selected={selectedTabPanel === 'search'}>
						{subjectAreaLinks.length > 0 && (
							<Grid paddingTop={false} paddingBottom={false}>
								<IndexPanel
									items={subjectAreaLinks}
									columns={numberOfColumnsForLinks}
								></IndexPanel>
							</Grid>
						)}
					</TabPanel>
				</div>

				{bottomItems.length > 0 && (
					<EpiFragments
						previousBlockType={blockTypeBefore_bottomItems}
						epi={epi}
						headingLevel={2}
						fragments={bottomItems}
						disableCustomHeadingLogic={disableCustomHeadingLogic}
					/>
				)}
			</Space>
		</>
	);
};

export default NVseSubjectAreaListingPage;

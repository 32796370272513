/**
 * Shared model types
 * (todo: Move this to another folder?)
 */
import { css } from 'styled-components';
import { ThemeType } from 'theme/theme';
import { FragmentModelTypes } from 'types/fragments';

export enum ButtonVariant {
	Primary = 'primary',
	Secondary = 'secondary',
	Tertiary = 'tertiary',
	Link = 'link',
	Microsite = 'microsite',
}

export enum ButtonColors {
	Primary = 'primary',
	Secondary = 'secondary',
	Blue = 'blue',
	Green = 'green',
	Brown = 'brown',
	Black = 'black',
	White = 'white',
	Grey = 'grey',
}

export type ImageObjectPosition = {
	horizontal?: number;
	vertical?: number;
};

export enum PuffTheme {
	NeutralWhite = 'neutralwhite',
	BlueDark = 'bluedark',
	BlueLight = 'bluelight',
	GreenDark = 'greendark',
	GreenLight = 'greenlight',
	BrownDark = 'browndark',
	BrownLight = 'brownlight',
	Black = 'black',
	MicrositeMainTheme = 'micrositeMainTheme',
}

export type LinkType = {
	heading: string;
	url: string;
	isFile?: boolean;
	target?: string;
	rel?: string;
};

export type AreaItem = {
	link: LinkType;
	numberOfPages: number | null;
};

export type SubjectAreaLink = {
	heading: string;
	url: string;
};

export type LinkExtendedType = {
	text: string;
	title: string | null;
	target: string | null;
	url: string;
	isFile?: boolean;
};

export type ChapterListType = {
	items: FragmentModelTypes[];
};

export type SiblingsList = {
	heading: string;
	items: LinkType[];
};

export const getIsDarkTheme = (theme: any): boolean => {
	switch (theme) {
		case PuffTheme.Black:
		case PuffTheme.BlueDark:
		case PuffTheme.BrownDark:
		case PuffTheme.GreenDark:
			return true;
		default:
			return false;
	}
};
export const getThemePrimaryButton = (
	theme: PuffTheme | null | undefined
): ButtonVariant => {
	switch (theme) {
		default:
		case PuffTheme.NeutralWhite:
			return ButtonVariant.Primary;
		case PuffTheme.BlueDark:
			return ButtonVariant.Secondary;
		case PuffTheme.BlueLight:
			return ButtonVariant.Primary;
		case PuffTheme.GreenDark:
			return ButtonVariant.Secondary;
		case PuffTheme.GreenLight:
			return ButtonVariant.Primary;
		case PuffTheme.BrownDark:
			return ButtonVariant.Secondary;
		case PuffTheme.BrownLight:
			return ButtonVariant.Primary;
	}
};
export const getThemeSecondaryButton = (
	theme: PuffTheme | null | undefined
): ButtonVariant => {
	switch (theme) {
		default:
		case PuffTheme.NeutralWhite:
			return ButtonVariant.Secondary;
		case PuffTheme.BlueDark:
			return ButtonVariant.Secondary;
		case PuffTheme.BlueLight:
			return ButtonVariant.Secondary;
		case PuffTheme.GreenDark:
			return ButtonVariant.Secondary;
		case PuffTheme.GreenLight:
			return ButtonVariant.Secondary;
		case PuffTheme.BrownDark:
			return ButtonVariant.Secondary;
		case PuffTheme.BrownLight:
			return ButtonVariant.Secondary;
	}
};
export const getThemeBackground = (
	themeContext: ThemeType,
	theme: PuffTheme | null | undefined
) => {
	switch (theme) {
		default:
		case PuffTheme.NeutralWhite:
			return themeContext.colors.white;
		case PuffTheme.BlueDark:
			return themeContext.colors.primary;
		case PuffTheme.BlueLight:
			return themeContext.colors.blue1;
		case PuffTheme.GreenDark:
			return themeContext.colors.secondaryGreen;
		case PuffTheme.GreenLight:
			return themeContext.colors.green1;
		case PuffTheme.BrownDark:
			return themeContext.colors.secondaryBrown;
		case PuffTheme.BrownLight:
			return themeContext.colors.brown1;
		case PuffTheme.Black:
			return themeContext.colors.black;
		case PuffTheme.MicrositeMainTheme:
			return themeContext.colors.tertiary;
	}
};
export const getThemeHeadingColor = (
	themeContext: ThemeType,
	theme: PuffTheme | null | undefined
) => {
	switch (theme) {
		default:
			return themeContext.colors.primary;
		case PuffTheme.NeutralWhite:
			return themeContext.colors.secondaryBlue;
		case PuffTheme.BlueDark:
			return themeContext.colors.brown1;
		case PuffTheme.BlueLight:
			return themeContext.colors.secondaryBlue;
		case PuffTheme.GreenDark:
			return themeContext.colors.brown1;
		case PuffTheme.GreenLight:
			return themeContext.colors.primary;
		case PuffTheme.BrownDark:
			return themeContext.colors.brown1;
		case PuffTheme.BrownLight:
			return themeContext.colors.secondaryBlue;
		case PuffTheme.Black:
			return themeContext.colors.brown1;
		case PuffTheme.MicrositeMainTheme:
			return themeContext.colors.primary;
	}
};
export const getThemeTextColor = (
	themeContext: ThemeType,
	theme: PuffTheme | null | undefined
) => {
	switch (theme) {
		default:
		case PuffTheme.NeutralWhite:
			return themeContext.palette.text.primary;
		case PuffTheme.BlueDark:
			return themeContext.colors.white;
		case PuffTheme.BlueLight:
			return themeContext.colors.primary;
		case PuffTheme.GreenDark:
			return themeContext.colors.white;
		case PuffTheme.GreenLight:
			return themeContext.colors.primary;
		case PuffTheme.BrownDark:
			return themeContext.colors.white;
		case PuffTheme.BrownLight:
			return themeContext.colors.primary;
		case PuffTheme.Black:
			return themeContext.colors.white;
		case PuffTheme.MicrositeMainTheme:
			return themeContext.colors.primary;
	}
};
export const getThemeBorderColor = (
	themeContext: ThemeType,
	theme: PuffTheme | null | undefined
) => {
	return getThemeTextColor(themeContext, theme);
};
export const getThemeSettings = (
	themeName: PuffTheme | null | undefined,
	themeContext: ThemeType
) => {
	// TODO: theme context and ThemeProps collide
	if (themeName && themeName.length) {
		return css`
			background-color: ${getThemeBackground(themeContext, themeName)};
			padding-top: ${(props) => props.theme.spacing.space5}rem;
			padding-bottom: ${(props) => props.theme.spacing.space5}rem;
		`;
	}
};
export type NumberRage = {
	from: number | null;
	to: number | null;
};
